//TODO rename
import { IFormData } from '../form-data.interface'

export function getSessionStorage(
  key: string,
  defaultValue?: IFormData,
): IFormData | undefined {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const settingDataToSessionStorage = (fD: IFormData) => {
  sessionStorage.setItem('formData', JSON.stringify(fD))
}
