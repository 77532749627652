import { useTranslation } from 'react-i18next'

import useGetSystemMessages from './hook'

import { ReactComponent as Icon } from './icon.svg'

import './system-messages.css'

const SystemMessages = () => {
  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  const systemMessagesQuery = useGetSystemMessages()

  const systemMessages = systemMessagesQuery.data?.messages ?? []

  if (!systemMessages.length) {
    return null
  }

  const blockers = systemMessages.filter((m) => m.type === 'blocking_message')

  return (
    <>
      {blockers.length ? (
        <div className="blockersWrapper">
          {blockers.map((b) => (
            <div key={b.id} className="blockerMessage">
              <div className="iconWrapper">
                <Icon />
              </div>

              <div className="title">
                {t('systemMessages.blocking.title')}
              </div>

              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: b.message[currentLanguage] ?? b.message.en }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default SystemMessages
