import { ISystemMessage } from './interface'

export const SYSTEM_MESSAGES: ISystemMessage[] = [
  {
    id: 'exodus-blocking',
    message: {
      en: 'Our system is performing a scheduled update. We apologize for the inconvenience. If you need help, please email us at <a href="mailto:help@driversbenefits.org">help@driversbenefits.org</a>. Thank you',
      es: 'Nuestro sistema está realizando una actualización programada. Pedimos disculpas por las molestias. Si necesita ayuda, envíenos un correo electrónico a <a href="mailto:help@driversbenefits.org">help@driversbenefits.org</a>. Gracias',
      fr: `Notre système effectue une mise à jour programmée. Nous nous excusons pour le dérangement. Si vous avez besoin d'aide, veuillez nous envoyer un e-mail à <a href="mailto:help@driversbenefits.org">help@driversbenefits.org</a>. Merci`,
      ch: '我们的系统正在执行预定的更新。我们对不便表示抱歉。如果您需要帮助，请发送电子邮件至 <a href="mailto:help@driversbenefits.org">help@driversbenefits.org</a>。谢谢'
    },
    type: 'blocking_message',
  },
]
