import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'


export const IntroText = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <div className="introText">
      <span className="page-num-indicator" id="page-1-indicator">
        {t('other.page1')}
      </span>
      <h2 id="intro-header">{t('other.mainTitle')}</h2>
      <div id="intro-content">
        <p>
          {t('other.eligleDrivers')}
        </p>
        <br />
        <p>
          {t('other.compleInfo')} <a href="tel:+18338148590">(833) 814-8590 </a> {t('other.hours')}
        </p>
        <p>
        <a href="https://ny.driversbenefits.org/benefits/" target={'_blank'}> {t('other.clickHere')} </a>
        </p>
        <br />
      </div>
    </div>
  )
}
