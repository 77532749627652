import { useFormik } from 'formik'
import { LeadContext, ILead, leadInitialValue } from '../../store/LeadContext'
import { validationSchema3 } from '../../common/utils/validationSchema'
import { preferredLanguageList, preferredLanguageCompleteList } from '../../common/utils/states-genders-languages'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { formCInputs } from '../../common/utils/formInput-values'
import { CheckboxComponent } from '../../common/components/checkbox-component/checkbox-component'
import { updateLead, updateLead2 } from '../../services/lead-api'
import { HealthcareComponent } from '../../common/components/healthcareInputs/healthcare-component'
import { TLCModalComponent } from '../../common/components/tlc-modal/tlc-modal-component'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { IFormProps } from 'src/common/utils/interfaces'


export const FormCV2: React.FC<IFormProps> = ({ storageData, language }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formDataSent, setFormDataSent] = useState<boolean>(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const leadContext = useContext(LeadContext)
  const { id } = useParams()
  const browserLang = navigator.language
  const userAgent = navigator.userAgent
  const { search } = useLocation()

  const sendData = async (values: Partial<ILead>) => {
    if (!leadContext) {
      throw new Error('Lead context not found')
    }

    const searchParams = new URLSearchParams(search)
    values.browserLanguage = browserLang
    values.browserDevice = userAgent
    values.utm = searchParams.get('utm_source') || ''
    try {
      await updateLead({...leadContext.lead, ...values } as any)
      setFormDataSent(true)
      leadContext.dispatch(leadInitialValue)
    } catch (error) {
      console.log('error', error)
    }
  }

  const formik = useFormik({
    initialValues: storageData,
    validationSchema: validationSchema3,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (!leadContext) {
        throw new Error('Lead context not found')
      }

      ReactGA.event({
        category: 'Submit Button',
        action: 'Form Submitted',
      })

      values.id = id
      values.formDropoff = false
      if(values.preferredLanguage === "Other"){
        values.preferredLanguage = values.preferredLanguageComplete
      }

      try {
        await sendData(values)
        sessionStorage.removeItem('beforeUnloadPreFill')
        sessionStorage.removeItem('formData')
        leadContext.dispatch(leadInitialValue)
        navigate(`/${language}/db-thank-you?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
      } catch (ex) {
        console.log(ex)
      }
    },
  })

  const {
    touched,
    handleSubmit,
    getFieldProps,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formik

  useEffect(() => {
    if (!Object.keys(errors).length) {
      return
    } else if (submitClicked && errors) {
      document.getElementById(Object.keys(errors)[0])?.focus()
      window.scrollTo(
        0,
        -100 +
          (document.getElementById(Object.keys(errors)[0])?.offsetTop || 0),
      )
      setSubmitClicked(false)
    }
  }, [errors, submitClicked])

  useEffect(() => {
    window.scrollTo(0, 0)

    const beforeUnloadHandler = async (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
      if (sessionStorage.getItem('beforeUnloadPreFill') && !formDataSent) {
        try {
          sendData({
            ...JSON.parse(sessionStorage.getItem('beforeUnloadPreFill') || ''),
            formDropoff: true,
          }).then(() => {
            sessionStorage.removeItem('beforeUnloadPreFill')
            setFormDataSent(true)
          })
        } catch (_) {
          sessionStorage.removeItem('beforeUnloadPreFill')
        }
      }
    }

    window.addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      sessionStorage.removeItem('beforeUnloadPreFill')
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      try {
        formik.values.id = id
        sessionStorage.setItem(
          'beforeUnloadPreFill',
          JSON.stringify(formik.values),
        )
      } catch (ex) {
        console.log(ex)
      }
    }
  })

  const handlerModal = () => {
    setShowModal(!showModal)
  }

  const preferredLanguageListRender = preferredLanguageList.map((option) => {
    return (
      <option
        key={option.value}
        disabled={option.disabled}
        value={option.value}
      >
        {t(`options.preferredLanguage.${option.label}`)}
      </option>
    )
  })

  const preferredLanguageCompleteListRender = preferredLanguageCompleteList.map((option) => {
    return (
      <option
        key={option.value}
        disabled={option.disabled}
        value={option.value}
      >
        {t(`options.preferredLanguage.${option.label}`)}
      </option>
    )
  })

  const renderFormCInputs = formCInputs.map((input) => {
    const { name, type, label, maxLength, placeholder, info, link } = input
    return (
      <div id={name} className="form-field" key={name}>
        <label id={name} htmlFor={name}>
          <div id={name} className="inputLabels">
            <p id={name} className="field">
              {t(`label.${name}`)}
            </p>
            <span id={name} className="aboveSubmit">
              {info ? t('other.info') : ''}
              <span id="tlc-link" onClick={handlerModal}>
                {name === 'tlcNumber' ? t('other.link') : null}
              </span>
            </span>
            {touched[name] && errors[name] ? (
              <span id={name} className="errorMessage">
                {t(`error.${errors[name]}`)}
              </span>
            ) : (
              <></>
            )}
          </div>
          <input
            id={name}
            type={type}
            maxLength={maxLength || 200}
            placeholder={placeholder}
            {...getFieldProps(name)}
            onChange={handleChange}
          />
        </label>
      </div>
    )
  })

  const handleCheckboxChange = (e: string) => {
    setFieldValue('carCompanies', e)
    setFieldTouched('carCompanies', e !== '')
  }

  const handleOnChange = (value: string, name: string) => {
    setFieldValue(name, value)
    setFieldTouched(name)
  }

  return (
    <form className="formC" onSubmit={handleSubmit}>
      <TLCModalComponent show={showModal} />
      <span className="page-num-indicator" id="page-2-indicator">
        {t('other.page3')}
      </span>
      {renderFormCInputs}

      <CheckboxComponent
        touched={touched.carCompanies}
        error={errors.carCompanies}
        onChange={handleCheckboxChange}
      />

      <div className="form-field">
        <div className="inputLabels">
          <p>{t('label.preferredLanguage')}</p>
          {touched.preferredLanguage && errors.preferredLanguage ? (
            <span className="errorMessage">{t(`error.${errors.preferredLanguage}`)}</span>
          ) : (
            <></>
          )}
        </div>
        <label htmlFor="preferredLanguage">
          <select
            id="preferredLanguage"
            onChange={handleChange}
            value={getFieldProps('preferredLanguage').value}
          >
            {preferredLanguageListRender}
          </select>
        </label>
        {getFieldProps('preferredLanguage').value === 'Other'
        ? <label htmlFor="preferredLanguageComplete">
          <p>Select one Language</p>
          <select
            id="preferredLanguageComplete"
            onChange={handleChange}
            value={getFieldProps('preferredLanguageComplete').value}
          >
            {preferredLanguageCompleteListRender}
          </select>
        </label>
        : null }
      </div>

      <HealthcareComponent
        touched={[touched.doYouHaveHealthInsurance]}
        error={[
          {
            name: 'doYouHaveHealthInsurance',
            error: errors.doYouHaveHealthInsurance,
          },
        ]}
        onChange={handleOnChange}
      />

      <p className="aboveSubmit">
      {t('other.byCompleteInfo')}{' '}
        <a
          href="https://driversbenefits.org/terms-of-use/"
          target="_blank"
          rel="noreferrer"
          title="Here"
          className="aboveSubmitLink"
        >
          {t('other.here')}..
        </a>{' '}
        {t('other.youAlso')}{' '}
        <a
          href="https://driversbenefits.org/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          className="aboveSubmitLink"
          title="here"
        >
          {t('other.here')}.
        </a>
      </p>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => setSubmitClicked(true)}
      >
        {t('other.submit')}
      </button>
      <p className="below-submit-titles">{t('other.facts')}</p>
      <p className="aboveSubmit">
        {t('other.asYouMayKnow')}
      </p>
      <p className="below-submit-titles">{t('other.questions')}</p>
      <p className="aboveSubmit">
        {t('other.callUs')}{' '}
        <a href="tel:+18338148590">(833) 814-8590</a>
      </p>
    </form>
  )
}
