import tlcImage from '../../../images/new-tlc-drivers-license.png'
// import { ReactComponent as CloseIcon } from '../../../images/svg/icons8-close.svg'
import { IoMdClose } from 'react-icons/io'
import { useEffect, useState } from 'react'

export const TLCModalComponent = (props: any) => {
  const [show, setShow] = useState(false)

  const showHandler = () => {
    setShow(false)
  }
  useEffect(() => {
    setShow(props.show)
  }, [props.show])
  return (
    <>
      {show && (
        <div className="modal-background" onClick={showHandler}>
          <div className="modal-container">
            <img src={tlcImage} alt="new tlc drivers license" />
            <div className="close-modal-icon">
              <IoMdClose />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
