import axios from 'axios'
import { ILead } from '../store/LeadContext'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// TODO need refactor it at all
// ========= [ POST Lead ] ========= //
export const createLead = async (lead: Partial<ILead>) => {return updateLead(lead)}

// ========= [ PATCH Lead for Page 2 ] ========= //
export const updateLead = async (lead: Partial<ILead>) => {
  //const url = `salesforce/leads`
  const url = '/v1/forms'
  console.log(lead);
  try {

    const _fields = [
      {
        name: 'preferredLanguage',
        input: 'basic',
        label: 'preferredLanguage',
        value: lead['preferredLanguage'],
      },
      {
        name: 'doYouHaveHealthInsurance',
        input: 'basic',
        label: 'doYouHaveHealthInsurance',
        value: lead['doYouHaveHealthInsurance'],
      },
      {
        name: 'doYouHaveHealthInsurance',
        input: 'basic',
        label: 'doYouHaveHealthInsurance',
        value: lead['doYouHaveHealthInsurance'],
      },
      {
        name: 'companies',
        input: 'basic',
        label: 'companies',
        value: lead['carCompanies'] as string[],
      },
    ];

    _fields.push(((lead['tlcNumber']?.length||0) > 7)?    {
        name: 'driversLicenseNumber',
        input: 'basic',
        label: 'driversLicenseNumber',
        value: lead['tlcNumber'],
      }:
      {
        name: 'tlcLicenseNumber',
        input: 'basic',
        label: 'tlcLicenseNumber',
        value: lead['tlcNumber'],
      },);


    const response = await api.put(url,

      {
        id: lead.id && lead.id!==''? lead.id:undefined,
        templateId: 'e22ed052-1a71-4aec-83a6-3cee09b51af9',
        email: lead['email'],
        documentNumber: lead['driversLicenseNumber'],
        isCompleted: lead.formDropoff === false,
        isReviewed: false,
        version: lead['formVariant'],
        payload: {
          steps: [
            {
              fields: [
                {
                  name: 'browserDevice',
                  input: 'basic',
                  label: 'browserDevice',
                  value: lead['browserDevice'],
                },
                {
                  name: 'browserLanguage',
                  input: 'basic',
                  label: 'browserLanguage',
                  value: lead['browserLanguage'],
                },
                {
                  name: 'source',
                  input: 'basic',
                  label: 'source',
                  value: lead['utm'],
                },
                {
                  name: 'email',
                  input: 'basic',
                  label: 'email',
                  value: lead['email'],
                },
                {
                  name: 'firstName',
                  input: 'basic',
                  label: 'firstName',
                  value: lead['firstName'],
                },
                {
                  name: 'lastName',
                  input: 'basic',
                  label: 'lastName',
                  value: lead['lastName'],
                },
                {
                  name: 'phoneNumber',
                  input: 'basic',
                  label: 'phoneNumber',
                  value: lead['phoneNumber'],
                },
                {
                  name: 'companies',
                  input: 'basic',
                  label: 'companies',
                  value: lead['carCompanies'] as string[],
                },
              ],
            },
            {
              fields: [
                {
                  name: 'gender',
                  input: 'basic',
                  label: 'gender',
                  value: lead['gender'],
                },
                {
                  name: 'birthDate',
                  input: 'basic',
                  label: 'birthDate',
                  value: lead['dateOfBirth'],
                },
                {
                  name: 'street',
                  input: 'basic',
                  label: 'street',
                  value: lead['streetAddress'],
                },
                {
                  name: 'city',
                  input: 'basic',
                  label: 'city',
                  value: lead['city'],
                },
                {
                  name: 'state',
                  input: 'basic',
                  label: 'state',
                  value: lead['stateSelectHome'],
                },
                {
                  name: 'postalCode',
                  input: 'basic',
                  label: 'postalCode',
                  value: lead['zipCode'],
                },
              ],
            },
            {
              fields: _fields,
            },
          ],
        },
      }
    )
    return response.data
  } catch (e) {
    return { error: e }
  }
}

// ========= [ PATCH Lead for Page 3 ] ========= //
export const updateLead2 = async (lead: Partial<ILead>) => {return updateLead(lead)}

