import { useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import './app.css'
import { Home } from './common/screens/home'
import { SecondScreen } from './common/screens/second-screen'
import { SubmitScreen } from './common/screens/submit-screen'
import { ThankYou } from './common/screens/thank-you'
import { ILead, LeadContext, leadInitialValue } from './store/LeadContext'
import ReactGA from 'react-ga'
import i18n from './i18n/i18n'

import config from './app.config'

import SystemMessages from './SystemMessages'
import { GooglePlacesApiProvider } from './store/GooglePlacesApiContext/GooglePlacesApiContext'


export const App = () => {
  const [storageData, setStorageData] =
    useState<Partial<ILead>>(leadInitialValue)

  const [lead, dispatch] = useState(leadInitialValue)

  useEffect(() => {
    const language = getLanguagePath()
    if (language) {
      i18n.changeLanguage(language);
    }
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const location = useLocation();

  const getLanguagePath = () => {
    const path = location.pathname.split('/')

    if (path.includes('en')) {
      return 'en'
    }
    if (path.includes('es')) {
      return 'es'
    }
    if (path.includes('fr')) {
      return 'fr'
    }
    if (path.includes('ch')) {
      return 'ch'
    }
    return undefined
  }

  if (config.APP_IS_TEMPORARILY_UNAVAILABLE) {
    return (
      <SystemMessages />
    )
  }

  return (
    <LeadContext.Provider value={{ lead, dispatch }}>
      <GooglePlacesApiProvider>
        <div className="App">
          <Routes>
            <Route
              path={getLanguagePath() ? `/${getLanguagePath()}` : '/'}
              element={<Home {...{ storageData, setStorageData }} language={getLanguagePath() ? getLanguagePath() : 'en'} />}
            />
            <Route
              path={getLanguagePath() ? `/${getLanguagePath()}/db-page-2/:id` : '/db-page-2/:id'}
              element={<SecondScreen {...{ storageData, setStorageData }} language={getLanguagePath() ? getLanguagePath() : 'en'} />}
            />
            <Route
              path={getLanguagePath() ? `/${getLanguagePath()}/db-page-3/:id` : '/db-page-3/:id'}
              element={<SubmitScreen {...{ storageData, setStorageData }} language={getLanguagePath() ? getLanguagePath() : 'en'} />}
            />
            <Route path={getLanguagePath() ? `/${getLanguagePath()}/db-thank-you` : '/db-thank-you'} element={<ThankYou />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </GooglePlacesApiProvider>
    </LeadContext.Provider>
  )
}
