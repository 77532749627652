/* eslint-disable import/no-anonymous-default-export */
export default {
  label: {
    firstName: '名字',
    lastName: '姓氏',
    email: '电子邮件',
    phoneNumber: '电话号码',
    preferredLanguage: '首选语言',
    carCompanies: '您为哪些黑车公司工作？',
    gender: '性别',
    dateOfBirth: '出生日期',
    streetAddress: '街道地址',
    city: '城市',
    stateSelectHome: '州/省',
    zipCode: '邮政编码',
    driversLicenseNumber: '驾驶证号码',
    driversLicenseNumberСonfirm: '重新输入驾照号码',
    tlcNumber: 'TLC 许可证号码',
    dlConfirm: '重新输入 TLC 许可证号码',
    whatStateDoYouDriveIn: '您在哪个州开车？',
    doYouHaveHealthInsurance: '您当前是否有健康保险？',
    mm: '月',
    dd: '日',
    yyyy: '年',
  },
  placeholder: {
    firstName: '输入您的名字',
    lastName: '输入您的姓氏',
    email: 'example@domain-name.com',
    phoneNumber: '(###) ###-####',
    gender: '选择性别',
    streetAddress: '输入您的街道地址',
    city: '输入城市',
    stateSelectHome: '选择州/省',
    zipCode: '输入您的邮编',
    driversLicenseNumber: '输入您的驾照号码',
    driversLicenseNumberСonfirm: '重新输入驾照号码',
    tlcNumber: '输入您的TLC号码',
    tlcNumberСonfirm: '重新输入TLC号码',
    other: '其他',
  },
  options: {
    preferredLanguage: {
      'Select Language': '选择语言',
      'Arabic': '阿拉伯语',
      'English': '英语',
      'Bengali': '孟加拉语',
      'Chinese': '中文',
      'French': '法语',
      'Hindi': '印地语',
      'Punjabi': '旁遮普语',
      'Russian': '俄语',
      'Spanish': '西班牙语',
      'Urdu': '乌尔都语',
      'Other': '其他语言',
      'Abkhazian': '阿布哈兹语',
      'Afar': '阿法尔语',
      'Afrikaans': '南非荷兰语',
      'Akan': '阿坎语',
      'Albanian': '阿尔巴尼亚语',
      'Amharic': '阿姆哈拉语',
      'Arabic': '阿拉伯语',
      'Aragonese': '阿拉贡语',
      'Armenian': '亚美尼亚语',
      'Assamese': '阿萨姆语',
      'Avaric': '阿瓦尔语',
      'Avestan': '阿维斯陀语',
      'Aymara': '艾马拉语',
      'Azerbaijani': '阿塞拜疆语',
      'Bambara': '班巴拉语',
      'Bashkir': '巴什基尔语',
      'Basque': '巴斯克语',
      'Belarusian': '白俄罗斯语',
      'Bengali': '孟加拉语',
      'Bislama': '比斯拉马语',
      'Bosnian': '波斯尼亚语',
      'Breton': '布列塔尼语',
      'Bulgarian': '保加利亚语',
      'Burmese': '缅甸语',
      'Catalan, Valencian': '加泰罗尼亚语',
      'Chamorro': '查莫罗语',
      'Chechen': '车臣语',
      'Chichewa, Chewa, Nyanja': '奇切瓦语',
      'Chinese': '中文',
      'Church Slavonic, Old Slavonic, Old Church Slavonic': '教会斯拉夫语',
      'Chuvash': '楚瓦什语',
      'Cornish': '康沃尔语',
      'Corsican': '科西嘉语',
      'Cree': '克里语',
      'Croatian': '克罗地亚语',
      'Czech': '捷克语',
      'Danish': '丹麦语',
      'Divehi, Dhivehi, Maldivian': '迪维希语',
      'Dutch, Flemish': '荷兰语，佛兰芒语',
      'Dzongkha': '宗喀语',
      'English': '英语',
      'Esperanto': '世界语',
      'Estonian': '爱沙尼亚语',
      'Ewe': '埃维语',
      'Faroese': '法罗语',
      'Fijian': '斐济语',
      'Finnish': '芬兰语',
      'French': '法语',
      'Western Frisian': '西弗里西亚语',
      'Fulah': '富拉语',
      'Gaelic, Scottish Gaelic': '盖尔语，苏格兰盖尔语',
      'Galician': '加利西亚语',
      'Ganda': '干达语',
      'Georgian': '格鲁吉亚语',
      'German': '德语',
      'Greek': '希腊语',
      'Kalaallisut, Greenlandic': '卡拉勒荣特语，格陵兰语',
      'Guarani': '瓜拉尼语',
      'Gujarati': '古吉拉特语',
      'Haitian, Haitian Creole': '海地克里奥尔语',
      'Hausa': '豪萨语',
      'Hebrew': '希伯来语',
      'Herero': '赫雷罗语',
      'Hindi': '印地语',
      'Hiri Motu': '希里莫图语',
      'Hungarian': '匈牙利语',
      'Icelandic': '冰岛语',
      'Ido': '伊多语',
      'Igbo': '伊博语',
      'Indonesian': '印度尼西亚语',
      'Interlingua': '国际语',
      'Interlingue, Occidental': '国际语，西方语',
      'Inuktitut': '因纽特语',
      'Inupiaq': '伊努庇克语',
      'Irish': '爱尔兰语',
      'Italian': '意大利语',
      'Japanese': '日语',
      'Javanese': '爪哇语',
      'Kannada': '卡纳达语',
      'Kanuri': '卡努里语',
      'Kashmiri': '克什米尔语',
      'Kazakh': '哈萨克语',
      'Central Khmer': '高棉语',
      'Kikuyu, Gikuyu': '基库尤语，吉库尤语',
      'Kinyarwanda': '基尼亚鲁旺达语',
      'Kirghiz, Kyrgyz': '柯尔克孜语',
      'Komi': '科米语',
      'Kongo': '刚果语',
      'Korean': '韩语',
      'Kuanyama, Kwanyama': '昆亚玛语，宽亚玛语',
      'Kurdish': '库尔德语',
      'Lao': '老挝语',
      'Latin': '拉丁语',
      'Latvian': '拉脱维亚语',
      'Limburgan, Limburger, Limburgish': '林堡语',
      'Lingala': '林加拉语',
      'Lithuanian': '立陶宛语',
      'Luba-Katanga': '卢巴-卡坦加语',
      'Luxembourgish, Letzeburgesch': '卢森堡语',
      'Macedonian': '马其顿语',
      'Malagasy': '马达加斯加语',
      'Malay': '马来语',
      'Malayalam': '马拉雅拉姆语',
      'Maltese': '马耳他语',
      'Manx': '马恩岛语',
      'Maori': '毛利语',
      'Marathi': '马拉地语',
      'Marshallese': '马绍尔语',
      'Mongolian': '蒙古语',
      'Nauru': '瑙鲁语',
      'Navajo, Navaho': '纳瓦霍语',
      'North Ndebele': '北恩德贝勒语',
      'South Ndebele': '南恩德贝勒语',
      'Ndonga': '恩敦加语',
      'Nepali': '尼泊尔语',
      'Norwegian': '挪威语',
      'Norwegian Bokmål': '书面挪威语',
      'Norwegian Nynorsk': '新挪威语',
      'Sichuan Yi, Nuosu': '四川彝语',
      'Occitan': '奥克语',
      'Ojibwa': '奥吉布瓦语',
      'Oriya': '奥里亚语',
      'Oromo': '奥罗莫语',
      'Ossetian, Ossetic': '奥塞梯语',
      'Pali': '巴利语',
      'Pashto, Pushto': '普什图语',
      'Persian': '波斯语',
      'Polish': '波兰语',
      'Portuguese': '葡萄牙语',
      'Punjabi, Panjabi': '旁遮普语',
      'Quechua': '克丘亚语',
      'Romanian, Moldavian, Moldovan': '罗马尼亚语',
      'Romansh': '罗曼什语',
      'Rundi': '隆迪语',
      'Russian': '俄语',
      'Northern Sami': '北萨米语',
      'Samoan': '萨摩亚语',
      'Sango': '桑戈语',
      'Sanskrit': '梵语',
      'Sardinian': '撒丁语',
      'Serbian': '塞尔维亚语',
      'Shona': '绍纳语',
      'Sindhi': '信德语',
      'Sinhala, Sinhalese': '僧伽罗语',
      'Slovak': '斯洛伐克语',
      'Slovenian': '斯洛文尼亚语',
      'Somali': '索马里语',
      'Southern Sotho': '南索托语',
      'Spanish': '西班牙语',
      'Sundanese': '巽他语',
      'Swahili': '斯瓦希里语',
      'Swati': '斯瓦特语',
      'Swedish': '瑞典语',
      'Tagalog': '他加禄语',
      'Tahitian': '塔希提语',
      'Tajik': '塔吉克语',
      'Tamil': '泰米尔语',
      'Tatar': '鞑靼语',
      'Telugu': '泰卢固语',
      'Thai': '泰语',
      'Tibetan': '藏语',
      'Tigrinya': '提格利尼亚语',
      'Tonga': '汤加语',
      'Tsonga': '宗加语',
      'Tswana': '茨瓦纳语',
      'Turkish': '土耳其语',
      'Turkmen': '土库曼语',
      'Twi': '契维语',
      'Uighur, Uyghur': '维吾尔语',
      'Ukrainian': '乌克兰语',
      'Urdu': '乌尔都语',
      'Uzbek': '乌兹别克语',
      'Venda': '文达语',
      'Vietnamese': '越南语',
      'Volapük': '沃拉普克语',
      'Walloon': '瓦隆语',
      'Welsh': '威尔士语',
      'Wolof': '沃洛夫语',
      'Xhosa': '科萨语',
      'Yiddish': '意第绪语',
      'Yoruba': '约鲁巴语',
      'Zhuang, Chuang': '壮语',
      'Zulu': '祖鲁语',
    },
    carCompanies: {
      uber: 'Uber',
      lyft: 'Lyft',
      vital: 'Vital',
      agape: 'Agape',
      other: '其他',
    },
    gender: {
      Male: '男性',
      Female: '女性',
      'Prefer not to say': '不愿透露',
      'Select Gender': '选择性别',
    },
    state: {
      'Select State / Province': '选择州/省',
      'New York': '纽约州',
      'New Jersey': '新泽西州',
      Connecticut: '康涅狄格州',
      Pennsylvania: '宾夕法尼亚州',
      Alabama: '阿拉巴马州',
      Alaska: '阿拉斯加州',
      Arizona: '亚利桑那州',
      Arkansas: '阿肯色州',
      California: '加利福尼亚州',
      Colorado: '科罗拉多州',
      Delaware: '特拉华州',
      'District Of Columbia': '哥伦比亚特区',
      Florida: '佛罗里达州',
      Georgia: '佐治亚州',
      Hawaii: '夏威夷州',
      Idaho: '爱达荷州',
      Illinois: '伊利诺伊州',
      Indiana: '印第安纳州',
      Iowa: '艾奥瓦州',
      Kansas: '堪萨斯州',
      Kentucky: '肯塔基州',
      Louisiana: '路易斯安那州',
      Maine: '缅因州',
      Maryland: '马里兰州',
      Massachusetts: '马萨诸塞州',
      Michigan: '密歇根州',
      Minnesota: '明尼苏达州',
      Mississippi: '密西西比州',
      Missouri: '密苏里州',
      Montana: '蒙大拿州',
      Nebraska: '内布拉斯加州',
      Nevada: '内华达州',
      'New Hampshire': '新罕布什尔州',
      'New Mexico': '新墨西哥州',
      'North Carolina': '北卡罗来纳州',
      'North Dakota': '北达科他州',
      Ohio: '俄亥俄州',
      Oklahoma: '俄克拉荷马州',
      Oregon: '俄勒冈州',
      'Rhode Island': '罗德岛州',
      'South Carolina': '南卡罗来纳州',
      'South Dakota': '南达科他州',
      Tennessee: '田纳西州',
      Texas: '德克萨斯州',
      Utah: '犹他州',
      Vermont: '佛蒙特州',
      Virginia: '弗吉尼亚州',
      Washington: '华盛顿州',
      'West Virginia': '西弗吉尼亚州',
      Wisconsin: '威斯康星州',
      Wyoming: '怀俄明州',
    },
    insurance: { Yes: '是', No: '否', Unsure: '不确定' },
  },
  error: {
    'First name is required': '必须提供名字',
    'Last name is required': '必须提供姓氏',
    'Email is required': '必须提供电子邮件',
    'Phone Number is required': '必须提供电话号码',
    'Invalid Phone Number': '电话号码无效',
    'Invalid email': '电子邮件无效',
    'Name too short': '名字太短',
    'Gender is required': '必须选择性别',
    'Date of Birth is required': '必须提供出生日期',
    'City is required': '必须提供城市',
    'Select a state': '必须选择州',
    'Address is required': '必须提供地址',
    'Zip code is required': '必须提供邮政编码',
    'Invalid Age': '年龄无效',
    'Address too short': '地址太短',
    'Invalid address':'無效地址',
    'Invalid city':'無效城市',
    'City too short': '城市名字太短',
    'Zip code not valid': '邮政编码无效',
    'Zip code too short': '邮政编码太短',
    'CompanyName': '必须提供至少一家汽车公司名称',
    'Must Provide At Least ONE Car Company Name': '必须提供至少一家汽车公司名称',
    'Must Select One': '必须选择一个',
    'Language is required': '必须选择语言',
    'TLC License # required': '必须提供TLC执照号码',
    'TLC # must match': 'TLC号码必须匹配'
  },
  other: {
    page1: '第1页，共3页',
    page2: '第2页，共3页',
    page3: '第3页，共3页',
    mainTitle: '激活您的福利',
    eligleDrivers: '所有符合条件的纽约出租车服务司机，包括Lyft、Uber和传统黑车基地的司机，都可以免费注册，获得由黑车基金会和独立司机工会提供的有价值的司机福利套餐！',
    compleInfo:
    '填写下面的信息以激活您的司机福利。或者打电话给我们，电话号码为',
    hours: '，周一至周五上午8:00至晚上8:00。',
    next: '下一步',
    submit: '提交',
    info: '没有TLC许可证号码？如果您不在纽约市驾驶，请输入您的驾驶执照号码。',
    link: '不知道什么是TLC吗？',
    here: '这里',
    byCompleteInfo: '通过填写上述信息并点击“提交”，您确认已阅读、理解并同意我们的使用条款，可以在以下位置找到',
    youAlso: '您还同意我们可以按照我们的隐私政策使用和披露您的信息，可以在以下位置找到',
    facts: '你应该知道的事实',
    asYouMayKnow: "你可能知道，黑车基金会为黑车行业的司机提供工人赔偿福利。为了帮助其受保护的司机保持健康并减少导致受伤的事故，黑车基金会赞助此计划，旨在减少伤害和医疗索赔。该计划是为黑车基金会的受保护司机提供的，并不旨在为您提供任何雇佣权利或以其他方式影响您在与黑车基金会或您所属的调度基地的独立承包商身份方面的地位。虽然我们希望您参加该计划，但该计划完全是自愿的，您不需要参加。如果您是雇员司机，则此计划不受您的雇主赞助或认可。该计划不旨在受到《雇员退休收入保障法案》（ERISA）或《患者保护和可负担性医疗法案》（PPACA）的覆盖。如果您注册该计划提供的远程医疗福利，则可能无法符合健康储蓄账户的资格。有关更多信息，请咨询您的税务顾问。黑车基金会保留随时整体或部分修订或终止该计划的权利，出于任何原因。",
    questions: '有问题吗？',
    callUs: '请在周一至周五上午8:00至下午8:00拨打以下电话：',
    fasterAccess: '欲更快获取信息，请下载我们的移动应用程序：',
    thankYou: '谢谢！',
    emailYouSoon: "我们将很快通过电子邮件告知您如何使用新福利。请务必检查您的垃圾邮件文件夹。",
    clickHere: "点击这里查看您将能够获得的好处"
  },
  systemMessages: {
    blocking: {
      title: '暂时不可用',
    },
  },
}
