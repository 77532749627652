import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useGooglePlacesApi from '../../hooks/google-place'

interface IGooglePlacesInput {
  name?: string
  value?: string
  touched?: boolean
  error?: string
  onChange: (value: google.maps.places.PlaceResult | null) => void
  setFieldTouched: (field: string, isTouched: boolean) => void
}


const GooglePlacesInput = (props: IGooglePlacesInput) => {
  const { value, touched, name, error, onChange, setFieldTouched } = props

  const selectRef = useRef(null)

  const { t } = useTranslation()

  const googlePlaceApi = useGooglePlacesApi()

  const [internalCurrentValue, setInternalCurrentValue] = useState<string>(value ?? '')
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  const [placesIsLoading, setPlacesIsLoading] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)

  useEffect(() => {
    if (selectRef.current) {
      const clickEventHandler = (event: MouseEvent | TouchEvent) => {
        const specifiedElement = selectRef.current
        if (specifiedElement && event.target instanceof Node) {
          const isClickInside = (specifiedElement as HTMLDivElement).contains(
            event.target,
          )
          if (!isClickInside) {
            setOptions([])
            setPlacesIsLoading(false)
          }
        }
      }

      const keyDownEventHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setOptions([])
          setPlacesIsLoading(false)
        }
      }

      window.addEventListener('mousedown', clickEventHandler, false)
      window.addEventListener('touchstart', clickEventHandler, false)
      window.addEventListener('keydown', keyDownEventHandler, false)

      return () => {
        window.removeEventListener('mousedown', clickEventHandler, false)
        window.removeEventListener('touchstart', clickEventHandler, false)
        window.removeEventListener('keydown', keyDownEventHandler, false)
      }
    }
  }, [selectRef])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched('streetAddress', true)
    const { value: currentValue } = event.currentTarget
    if (currentValue.length > 0) {
      if (!placesIsLoading) {
        setPlacesIsLoading(true)
        googlePlaceApi?.findPlace(event.currentTarget.value).then((results) => {
          if (results?.predictions) {
            const newOptions = results.predictions
              .filter((p) => p.types.includes('street_address'))
              .map((p) => ({
                value: p.place_id,
                label: p.description,
              }))
            setOptions(newOptions)
            setPlacesIsLoading(false)
          }
        })
      }
      setInternalCurrentValue(currentValue)
      onChange({ address_components: [], adr_address: currentValue } as google.maps.places.PlaceResult)
    } else {
      setInternalCurrentValue('')
      if (onChange) {
        onChange(null)
      }
    }
  }

  const handleSelect = (option: { value: string; label: string }) => {
    googlePlaceApi?.getPlaceById(option.value, (result) => {
      setOptions([])
      setInternalCurrentValue(option.label)

      if (onChange) {
        onChange(result)
      }
    })
  }

  const handleClick = () => {
    setActive(!active)
  }
  return (
    <div className="form-field" ref={selectRef}>

      <label htmlFor={name}>
        <div className="inputLabels">
          <p className="field">{t(`label.${name}`)}</p>
          {touched && error && (
            <span className="errorMessage">{t(`error.${error}`)}</span>
          )}
        </div>
        <input
          onClick={handleClick}
          onBlur={handleClick}
          value={internalCurrentValue}
          className={`inputGooglePlaces ${active ? 'active' : ''}`}
          placeholder=""
          onChange={handleChange}
        />
      </label>

      {options.length ? (
        <div className="basicOptions basicOptionsPlaces">
          {options?.map((o) => (
            <div className="place-option" key={o.value} onClick={() => handleSelect(o)}>
              {o.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default GooglePlacesInput
