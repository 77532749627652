import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './app'
import { BrowserRouter as Router } from 'react-router-dom'
import { aBTest } from './utils/ab-tests'
import 'src/i18n/i18n'
import ReactPixel from 'react-facebook-pixel';

;(async () => {
  if (process.env.REACT_APP_IS_AB_TEST_ON === 'on') {
    await aBTest(
      process.env.REACT_APP_AB_TEST_VERSION_1 || '',
      process.env.REACT_APP_AB_TEST_VERSION_2 || '',
      process.env.REACT_APP_AB_TEST_URL || '',
    )
  }

  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('421425925790999', {} as any, options);

  ReactPixel.pageView();
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
