import { useContext } from 'react'

import { GooglePlacesApiContext } from '../../store/GooglePlacesApiContext/GooglePlacesApiContext'

const useGooglePlacesApi = () => {
  const context = useContext(GooglePlacesApiContext)

  return context
}

export default useGooglePlacesApi
