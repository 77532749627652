import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CheckboxComponent = (props: any): ReactElement => {
  const { t } = useTranslation()
  const { touched, error, onChange } = props
  const [inputValue, setValueInput] = useState<string[]>([])
  const [otherOptionCheckbox, setOtherOptionCheckbox] = useState<boolean>(false)
  const [otherOptionValue, setOtherOptionValue] = useState<string>('')

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetVal = event.target.value
    if (targetVal === null) {
      const anotherCompanyValue = inputValue.filter(
        (company: string) =>
          company !== 'Uber' && company !== 'Lyft' && company !== 'Vital',
      )
      const newCarCompanies = inputValue.filter(
        (company: string) => company !== anotherCompanyValue[0],
      )
      onChange(newCarCompanies)
      setValueInput(newCarCompanies)
      return
    }

    if (otherOptionCheckbox && inputValue.includes(targetVal)) {
      if (targetVal !== otherOptionValue) {
        const newInputValue = inputValue.filter(
          (value: string) => value !== targetVal,
        )
        onChange(newInputValue)
        setValueInput(newInputValue)
        return
      }
      return
    }

    if (otherOptionCheckbox && !inputValue.includes(targetVal)) {
      const newCompaniesValues = inputValue.filter((company) => {
        if (
          company === 'Uber' ||
          company === 'Lyft' ||
          company === 'Vital' ||
          company === otherOptionValue
        ) {
          return company
        }
      })
      onChange(newCompaniesValues.concat(targetVal))
      setValueInput(newCompaniesValues.concat(targetVal))
      return
    }

    if (!otherOptionCheckbox && inputValue.includes(targetVal)) {
      const newInputValue = inputValue.filter(
        (value: string) => value !== targetVal,
      )
      onChange(newInputValue)
      setValueInput(newInputValue)
      return
    }

    if (!otherOptionCheckbox && !inputValue.includes(targetVal)) {
      onChange(inputValue.concat(targetVal))
      setValueInput(inputValue.concat(targetVal))
      return
    }
  }

  const handleOther = () => {
    setOtherOptionCheckbox(!otherOptionCheckbox)
  }

  const getError = () => {
    let errorMessage
    if (touched?.length === 0 && error) {
      errorMessage = t(`error.${error}`)
    }
    if (touched?.length === 0 && !error) {
      errorMessage = t(`error.${error}`)
    }
    return errorMessage
  }

  return (
    <>
      <div id="carCompanies">
        <label htmlFor="carCompanies" id="carCompaniesLabel">
          <p>{t('label.carCompanies')}</p>
          {getError() ? (
            <span id="carCompaniesErrorText" className="errorMessage">
              {getError()}
            </span>
          ) : (
            <></>
          )}
        </label>

        <div
          className={
            'checkbox-option ' +
            (inputValue.includes('Uber') ? 'checkedBox' : 'uncheckedBox')
          }
        >
          <input
            id="uberCheck"
            type="checkbox"
            value="Uber"
            checked={inputValue.includes('Uber')}
            onChange={(e) => handleOnChange(e)}
          />
          <label htmlFor="uberCheck" className="option">
            <span className="checkbox-label">{t('options.carCompanies.uber')}</span>
          </label>
        </div>
        <div
          className={
            'checkbox-option ' +
            (inputValue.includes('Lyft') ? 'checkedBox' : 'uncheckedBox')
          }
        >
          <input
            id="lyftCheck"
            type="checkbox"
            value="Lyft"
            checked={inputValue.includes('Lyft')}
            onChange={(e) => handleOnChange(e)}
          />
          <label htmlFor="lyftCheck" className="option">
            <span className="checkbox-label">{t('options.carCompanies.lyft')}</span>
          </label>
        </div>
        <div
          className={
            'checkbox-option ' +
            (inputValue.includes('Vital') ? 'checkedBox' : 'uncheckedBox')
          }
        >
          <input
            id="vitalCheck"
            type="checkbox"
            value="Vital"
            checked={inputValue.includes('Vital')}
            onChange={(e) => handleOnChange(e)}
          />
          <label htmlFor="vitalCheck" className="option">
            <span className="checkbox-label">{t('options.carCompanies.vital')}</span>
          </label>
        </div>
        <div
          className={
            'checkbox-option ' +
            (inputValue.includes('Agape') ? 'checkedBox' : 'uncheckedBox')
          }
        >
          <input
            id="agapeCheck"
            type="checkbox"
            value="Agape"
            checked={inputValue.includes('Agape')}
            onChange={(e) => handleOnChange(e)}
          />
          <label htmlFor="agapeCheck" className="option">
            <span className="checkbox-label">{t('options.carCompanies.agape')}</span>
          </label>
        </div>

        <div
          className={
            'checkbox-option ' +
            (otherOptionCheckbox ? 'checkedBox' : 'uncheckedBox')
          }
        >
          <input
            id="otherCheck"
            type="checkbox"
            value="Other"
            checked={otherOptionCheckbox}
            onChange={() => handleOther()}
          />
          <label htmlFor="otherCheck" className="option">
            <span className="checkbox-label">{t('options.carCompanies.other')}</span>
          </label>
        </div>
      </div>
      {otherOptionCheckbox ? (
        <div className="form-field">
          <div className="inputLabels">
            <p>Other</p>
          </div>
          <label htmlFor="otherInput" className="option">
            <input
              id="otherInput"
              type="text"
              value={otherOptionValue}
              // onChange={(e) => handleOtherInput(e)}
              onChange={(e) => setOtherOptionValue(e.target.value)}
              onBlur={(e) => handleOnChange(e)}
            />
          </label>
        </div>
      ) : null}
    </>
  )
}
