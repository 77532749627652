/* eslint-disable import/no-anonymous-default-export */
export default {
  label: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phoneNumber: 'Número de telefono',
    preferredLanguage: 'Lenguaje de preferencia',
    carCompanies: 'Para qué compañía de automóviles trabaja',
    dateOfBirth: 'Fecha de nacimiento',
    gender: 'Género',
    streetAddress: 'Dirección',
    city: 'Ciudad',
    stateSelectHome: 'Estado/Provincia',
    zipCode: 'Código postal',
    driversLicenseNumber: "Número de licencia de conducir",
    driversLicenseNumberСonfirm: 'Confirme el número de licencia de conducir',
    tlcNumber: 'Número de TLC',
    dlConfirm: 'Confirme el número de TLC',
    doYouHaveHealthInsurance: '¿Tiene seguro médico?',
    mm: 'MM',
    dd: 'DD',
    yyyy: 'AAAA',
    other: 'Otro',
  },
  placeholder: {
    firstName: 'Ingrese su nombre',
    lastName: 'ingrese su apellido',
    email: 'example@domain-name.com',
    phoneNumber: '(###) ###-####',
    gender: 'Seleccione su género',
    streetAddress: 'Ingrese su dirección',
    city: 'Ingrese su ciudad',
    stateSelectHome: 'Seleccione su estado',
    zipCode: 'Ingrese su código postal',
    driversLicenseNumber: "Ingrese su número de licencia de conducir",
    driversLicenseNumberСonfirm: 'confirme su número de licencia de conducir',
    tlcNumber: 'Ingrese su número de TLC',
    tlcNumberСonfirm: 'Confirme su número de TLC',
    other: 'Otro',
  },
  options: {
    preferredLanguage: {
      'Select Language': 'Seleccionar Idioma',
      'Árabe': 'Árabe',
      'Inglés': 'Inglés',
      'Bengalí': 'Bengalí',
      'Chino': 'Chino',
      'Francés': 'Francés',
      'Hindi': 'Hindi',
      'Panyabí': 'Panyabí',
      'Ruso': 'Ruso',
      'Español': 'Español',
      'Urdu': 'Urdu',
      'Otro': 'Otro',
      'Abjasio': 'Abjasio',
      'Afar': 'Afar',
      'Afrikáans': 'Afrikáans',
      'Akan': 'Akan',
      'Albanés': 'Albanés',
      'Amárico': 'Amárico',
      'Árabe': 'Árabe',
      'Aragonés': 'Aragonés',
      'Armenio': 'Armenio',
      'Asamés': 'Asamés',
      'Ávaro': 'Ávaro',
      'Avestán': 'Avestán',
      'Aimara': 'Aimara',
      'Azerbaiyano': 'Azerbaiyano',
      'Bambara': 'Bambara',
      'Bashkir': 'Bashkir',
      'Vasco': 'Vasco',
      'Bielorruso': 'Bielorruso',
      'Bengalí': 'Bengalí',
      'Bislama': 'Bislama',
      'Bosnio': 'Bosnio',
      'Bretón': 'Bretón',
      'Búlgaro': 'Búlgaro',
      'Birmano': 'Birmano',
      'Catalán, Valenciano': 'Catalán, Valenciano',
      'Chamorro': 'Chamorro',
      'Checheno': 'Checheno',
      'Chichewa, Chewa, Nyanja': 'Chichewa, Chewa, Nyanja',
      'Chino': 'Chino',
      'Eslavo Eclesiástico, Eslavo Antiguo, Antiguo Eslavo Eclesiástico': 'Eslavo Eclesiástico, Eslavo Antiguo, Antiguo Eslavo Eclesiástico',
      'Chuvasio': 'Chuvasio',
      'Córnico': 'Córnico',
      'Corso': 'Corso',
      'Cree': 'Cree',
      'Croata': 'Croata',
      'Checo': 'Checo',
      'Danés': 'Danés',
      'Divehi, Dhivehi, Maldivo': 'Divehi, Dhivehi, Maldivo',
      'Holandés, Flamenco': 'Holandés, Flamenco',
      'Dzongkha': 'Dzongkha',
      'Inglés': 'Inglés',
      'Esperanto': 'Esperanto',
      'Estonio': 'Estonio',
      'Ewé': 'Ewé',
      'Feroés': 'Feroés',
      'Fiyiano': 'Fiyiano',
      'Finlandés': 'Finlandés',
      'Francés': 'Francés',
      'Frisón Occidental': 'Frisón Occidental',
      'Swati': 'Swati',
      'Swedish': 'Swedish',
      'Tagalog': 'Tagalog',
      'Tahitian': 'Tahitian',
      'Tajik': 'Tajik',
      'Tamil': 'Tamil',
      'Tatar': 'Tatar',
      'Telugu': 'Telugu',
      'Thai': 'Thai',
      'Tibetan': 'Tibetan',
      'Tigrinya': 'Tigrinya',
      'Tonga (Tonga Islands)': 'Tonga (Tonga Islands)',
      'Tsonga': 'Tsonga',
      'Tswana': 'Tswana',
      'Turkish': 'Turkish',
      'Turkmen': 'Turkmen',
      'Twi': 'Twi',
      'Uighur, Uyghur': 'Uighur, Uyghur',
      'Ukrainian': 'Ukrainian',
      'Urdu': 'Urdu',
      'Uzbek': 'Uzbek',
      'Venda': 'Venda',
      'Vietnamese': 'Vietnamese',
      'Volapük': 'Volapük',
      'Walloon': 'Walloon',
      'Welsh': 'Welsh',
      'Wolof': 'Wolof',
      'Xhosa': 'Xhosa',
      'Yiddish': 'Yiddish',
      'Yoruba': 'Yoruba',
      'Zhuang, Chuang': 'Zhuang, Chuang',
      'Zulu': 'Zulu'
    },
    carCompanies: {
      uber: 'Uber',
      lyft: 'Lyft',
      vital: 'Vital',
      agape: 'Agape',
      other: 'Otro',
    },
    gender: {
      Male: 'Masculino',
      Female: 'Femenino',
      'Prefer not to say': 'Prefiero no decirlo',
      'Select Gender': 'Selecione un género',
    },
    state: {
      'Select State / Province': 'Selecione un estado / provincia',
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    insurance: { Yes: 'Si', No: 'No', Unsure: 'Sin seguro' },
  },
  error: {
    'First name is required': 'El nombre es obligatorio',
    'Last name is required': 'El apellido es obligatorio',
    'Email is required': 'El correo electrónico es obligatorio',
    'Phone Number is required': 'El número de teléfono es obligatorio',
    'Invalid Phone Number': 'Número de teléfono inválido',
    'Invalid email': 'Correo electrónico inválido',
    'Invalid address': 'Dirección inválida',
    'Invalid city': 'Ciudad inválida',
    'Name too short': 'El nombre es demasiado corto',
    'Gender is required': 'El género es obligatorio',
    'Date of Birth is required': 'La fecha de nacimiento es obligatoria',
    'City is required': 'La ciudad es obligatoria',
    'Select a state': 'Seleccione un estado',
    'Address is required': 'La dirección es obligatoria',
    'Zip code is required': 'El código postal es obligatorio',
    'Invalid Age': 'Edad inválida',
    'Address too short': 'La dirección es demasiado corta',
    'City too short': 'La ciudad es demasiado corta',
    'Zip code not valid': 'Código postal inválido',
    'Zip code too short': 'El código postal es demasiado corto',
    'CompanyName': 'Debe proporcionar al menos un nombre de empresa de automóviles',
    'Must Provide At Least ONE Car Company Name': 'Debe proporcionar al menos un nombre de empresa de automóviles',
    'Must Select One': 'Debe seleccionar uno',
    'Language is required': 'El idioma es obligatorio',
    'TLC License # required': 'Se requiere número de licencia TLC',
    'TLC # must match': 'El número de TLC debe coincidir'
  },
  other: {
    page1: 'Página 1 de 3',
    page2: 'Página 2 de 3',
    page3: 'Página 3 de 3',
    mainTitle: 'Activa tus Beneficios',
    eligleDrivers: 'Todos los conductores elegibles de los servicios de Black Car de NY, incluyendo Lyft, Uber y las bases más tradicionales de la industria Black Car, pueden registrarse de forma gratuita para recibir un valioso paquete de beneficios para conductores, proporcionados por The Black Car Fund y el Independent Drivers Guild!',
    compleInfo: 'Completa la información a continuación para activar tus beneficios de conductor. O llámanos al',
    hours: 'de \nLun a Vie de 8:00 am a 8:00 pm.',
    next: 'Siguiente',
    submit: 'Enviar',
    info: "¿No tienes un número de licencia TLC? Ingresa el número de licencia de tu conductor si no conduces en la ciudad de Nueva York.",
    link: "¿No sabes qué es TLC?",
    here: 'aquí',
    byCompleteInfo: 'Al completar la información anterior y hacer clic en "Enviar", confirmas que has leído, comprendido y aceptado estar sujeto a nuestros Términos de uso, que puedes encontrar',
    youAlso: 'También aceptas que podemos utilizar y divulgar tu información tal como se establece en nuestra Política de privacidad, que puedes encontrar',
    facts: 'Hechos que debes saber',
    asYouMayKnow: "Como podrías saber, el Black Car Fund ofrece a los conductores de la industria de los autos negros beneficios de compensación laboral. Para ayudar a sus conductores cubiertos a mantenerse saludables y reducir los accidentes que conducen a lesiones, el Black Car Fund patrocina este Programa con el propósito de reducir reclamos de lesiones y médicos. El Programa se te proporciona como conductor cubierto por el Black Car Fund y no tiene la intención de brindarte ningún derecho de empleo o de afectar de otra manera tu estado como contratista independiente con respecto al Black Car Fund o a la base de despacho con la que estás afiliado. Aunque esperamos que participes en el Programa, el Programa es completamente voluntario y no estás obligado a participar. Si eres un conductor empleado, este Programa no está patrocinado ni respaldado por tu empleador. El Programa no tiene la intención de estar cubierto por la Ley de Seguridad de Ingreso a la Jubilación del Empleado (ERISA) o la Ley de Protección al Paciente y Cuidado de Salud Asequible (PPACA). Si te registras para los beneficios de telemedicina disponibles en el Programa, es posible que no seas elegible para contribuir a una cuenta de ahorro de salud. Consulta con tu asesor fiscal para obtener más información. El Black Car Fund se reserva el derecho de revisar o terminar este Programa en su totalidad o en parte en cualquier momento y por cualquier motivo.",
    questions: '¿Preguntas?',
    callUs: 'Llámanos de Lun a Vie de 8:00 a.m. a 8:00 p.m. hora del este al',
    fasterAccess: 'Para un acceso más rápido, descarga nuestra aplicación móvil:',
    thankYou: '¡Gracias!',
    emailYouSoon: "Próximamente le enviaremos un correo electrónico sobre cómo utilizar sus nuevos beneficios. Asegúrese de revisar su carpeta de correo no deseado.",
    clickHere: "Haz clic aquí para ver los beneficios a los que podrás acceder."
  },
  systemMessages: {
    blocking: {
      title: 'Temporalmente No Disponible',
    },
  },
}
