import { Header } from '../../common/components/header'
import { IntroText } from '../../common/components/intro-text'
import { FormA } from '../../react-formik-v1/components/form-a-formik'
import { FormAV2 } from '../../react-formik-v2/components/form-a-formik'
import { IFormProps } from '../utils/interfaces'

export const Home = ({ storageData, setStorageData, language }: IFormProps) => {
  return (
    <>
      <Header />
      <div className="form-section home-screen">
        <IntroText />
        <div className="form-container">
        {/*  {localStorage.getItem('formVariant') === 'BCF-React-Lucy' ? (
            <FormA {...{ storageData, setStorageData, language }} />
          ) : (*/}
            <FormAV2 {...{ storageData, setStorageData, language }} />
       {/*   )}*/}
        </div>
      </div>
    </>
  )
}
