// ======================= [React-V1 Form] ======================= //
export interface IInputLists {
  label: string
  name: string
  type: string
  placeholder?: string
  radioValue?: Array<string>
  maxLength?: number
  minLength?: number
  info?: string
  link?: string
  onChange?: (value: string) => string
}

export interface IRadioInputs {
  label: string
  name: string
  type: string
  radioValue: Array<string>
  id: Array<string>
  onChange?: (value: string) => string
}

export const formAInputs: Array<IInputLists> = [
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter First Name',
    type: 'text',
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter Last Name',
    type: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'example@domain-name.com',
    type: 'text',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    placeholder: '(###) ###-####',
    type: 'tel',
    // minLength: 13,
    maxLength: 14,
  },
]

export const formBInputsV1: Array<IInputLists> = [
  // {
  //   label: 'Date of Birth',
  //   name: 'dateOfBirth',
  //   placeholder: 'YYYY-MM-DD',
  //   type: 'date',
  //   maxLength: 10,
  // },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    placeholder: 'Enter City',
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
    placeholder: 'Enter Zip Code',
    type: 'text',
    minLength: 5,
    maxLength: 5,
  },
]

export const formBInputsV2: Array<IInputLists> = [
  // {
  //   label: 'Date of Birth',
  //   name: 'dateOfBirth',
  //   placeholder: 'YYYY-MM-DD',
  //   type: 'date',
  //   // maxLength: 10,
  // },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    placeholder: 'Enter City',
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
    placeholder: 'Enter Zip Code',
    type: 'text',
    minLength: 5,
    maxLength: 5,
  },
]

export const formCInputs: Array<IInputLists> = [
  {
    label: 'TLC License #',
    name: 'tlcNumber',
    minLength: 5,
    placeholder: 'Enter Your TLC',
    type: 'text',
    info: "Don't have a TLC License number? Enter your driver's license number if you don't drive in New York City.",
    link: "Don't know what TLC is?",
  },
  {
    label: 'Re-Enter TLC License #',
    name: 'dlConfirm',
    placeholder: '',
    type: 'text',
  },
]

export const healthcareRadioInputs: Array<IRadioInputs> = [
  {
    label: 'Do you currently have health insurance?',
    name: 'doYouHaveHealthInsurance',
    type: 'radio',
    radioValue: ['Yes', 'No', 'Unsure'],
    id: [
      'doYouHaveHealthInsurance-yes',
      'doYouHaveHealthInsurance-no',
      'doYouHaveHealthInsurance-unsure',
    ],
  },
  // {
  //   label:
  //     'If yes, is your coverage through a plan from the state (such as Medicaid or the New York Essential Health Plan)?',
  //   name: 'coverageThroughTheState',
  //   type: 'radio',
  //   radioValue: ['Yes', 'No', 'Unsure'],
  //   id: [
  //     'coverageThroughTheState-yes',
  //     'coverageThroughTheState-no',
  //     'coverageThroughTheState-unsure',
  //   ],
  // },
  // {
  //   label:
  //     'If you have health insurance, do you pay MORE than $20 per month for your coverage?',
  //   name: 'payMoreThan20',
  //   type: 'radio',
  //   radioValue: ['Yes', 'No', 'Unsure'],
  //   id: ['payMoreThan20-yes', 'payMoreThan20-no', 'payMoreThan20-unsure'],
  // },
]
