import * as Yup from 'yup'
import moment from 'moment'

export const validationSchema1 = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Name too short')
    .matches(/^[a-zA-Z\s-]/g, {
      message: 'First name not valid',
      excludeEmptyString: true,
    })
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Name too short')
    .matches(/^[a-zA-Z\s-]/g, {
      message: 'Last Name not valid',
      excludeEmptyString: true,
    })
    .required('Last name is required'),
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: 'Invalid email',
      },
    )
    .required('Email is required'),
  phoneNumber: Yup.string()
    .min(14, 'Invalid Phone Number')
    .max(14, 'Invalid Phone Number')
    .required('Phone Number is required'),
})

export const validationSchema2 = Yup.object().shape({
  gender: Yup.string().required('Gender is required'),
  dateOfBirth: Yup.string()
    .test('dateOfBirth', 'Invalid Age', function(value: string | undefined) {
      const valueArray = value?.split('-')
      if (
        valueArray &&
        moment(Date.now()).diff(
          new Date(+valueArray[0], +valueArray[1] - 1, +valueArray[2]),
          'years',
        ) < 10
      ) {
        return false
      } else if (
        valueArray &&
        moment(Date.now()).diff(
          new Date(+valueArray[0], +valueArray[1] - 1, +valueArray[2]),
          'years',
        ) > 100
      ) {
        return false
      } else if (value === 'Invalid date') {
        return false
      } else {
        return true
      }
    })
    .required('Date of Birth is required'),
  streetAddress: Yup.string().matches(/^[a-zA-Z0-9\s\-\&\.]*$/, {
    message: 'Invalid address',
    excludeEmptyString: true,
  })
    .min(12, 'Address too short')
    .required('Address is required'),
  city: Yup.string().min(3, 'City too short').matches(/^[a-zA-Z\s\-]*$/, {
    message: 'Invalid city',
    excludeEmptyString: true,
  }).required('City is required'),
  stateSelectHome: Yup.string().required('Select a state'),
  zipCode: Yup.string()
    .min(5, 'Zip code too short')
    .max(5, 'Zip code too long')
    .matches(/^\d{5}(-\d{4})?$/, {
      message: 'Zip code not valid',
      excludeEmptyString: true,
    })
    .required('Zip code is required'),
})

export const validationSchema3 = Yup.object().shape({
  tlcNumber: Yup.string().required('TLC License # required'),
  // driversLicenseNumber: Yup.string().required('TLC License # required'),
  dlConfirm: Yup.string().test(
    'dl-match',
    'TLC # must match',
    function(value) {
      return this.parent.tlcNumber === value
      // return this.parent.driversLicenseNumber === value
    },
  ),
  carCompanies: Yup.array().test(
    'car-required',
    'Must Provide At Least ONE Car Company Name',
    function(value) {
      return !!value?.length
    },
  ),
  otherCarCompany: Yup.string().test(
    'other-required',
    'Company name is required',
    function(value) {
      return !this.parent.carCompanies?.includes('Other') || !!value
    },
  ),
  preferredLanguage: Yup.string().required('Language is required'),
  doYouHaveHealthInsurance: Yup.string().required('Must Select One'),
  // coverageThroughTheState: Yup.string().when('doYouHaveHealthInsurance', {
  //   is: 'Yes',
  //   then: Yup.string().required('Must Select One'),
  // }),
  // payMoreThan20: Yup.string().when('doYouHaveHealthInsurance', {
  //   is: 'Yes',
  //   then: Yup.string().required('Must Select One'),
  // }),
})

// export const validationSchema4 = Yup.object().shape({
//   driversLicenseNumber: Yup.string().required('Drivers License # required'),
//   dlConfirm: Yup.string().test(
//     'dl-match',
//     'Driver License must match',
//     function (value) {
//       return this.parent.driversLicenseNumber === value
//     },
//   ),
//   carCompanies: Yup.array().test(
//     'car-required',
//     'Must Provide At Least ONE Car Company Name',
//     function (value) {
//       return !!value?.length
//     },
//   ),
//   otherCarCompany: Yup.string().test(
//     'other-required',
//     'Company name is required',
//     function (value) {
//       return !this.parent.carCompanies?.includes('Other') || !!value
//     },
//   ),
//   preferredLanguage: Yup.string().required('Language is required'),
//   hasHealthcare: Yup.boolean(),
//   doYouHaveHealthInsurance: Yup.string().required('Must Select One'),
//   coverageThroughTheState: Yup.string().when('hasHealthcare', {
//     is: true,
//     then: Yup.string().required('Must Select One'),
//   }),
//   payMoreThan20: Yup.string().when('hasHealthcare', {
//     is: true,
//     then: Yup.string().required('Must Select One'),
//   }),
// })
