import dbLogo from '../../images/db-logo.png'
import { ReactElement } from 'react'
import '../../app.css'

export const Header = (): ReactElement => {
  return (
    <div id="db-logo-container">
      <img src={dbLogo} alt="Drivers Benefits Logo" id="db-logo" />
    </div>
  )
}
