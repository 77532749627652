import { useFormik } from 'formik'
import { LeadContext, ILead } from '../../store/LeadContext'
import { validationSchema2 } from '../../common/utils/validationSchema'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { formBInputsV2 } from '../../common/utils/formInput-values'
import { settingDataToSessionStorage } from '../../utils/storage'
import {
  genderList,
  statesArray,
} from '../../common/utils/states-genders-languages'
import { updateLead } from '../../services/lead-api'
import ReactGA from 'react-ga'
import DateInput from '../../common/components/date-input'
import { useTranslation } from 'react-i18next'
import { IFormProps } from 'src/common/utils/interfaces'
import GooglePlacesInput from 'src/common/components/google-place-input/google-place-input'

export const FormBV2: React.FC<IFormProps> = ({
  setStorageData,
  storageData,
  language,
}: IFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formDataSent, setFormDataSent] = useState<boolean>(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const leadContext = useContext(LeadContext)
  const { id } = useParams()
  const browserLang = navigator.language
  const userAgent = navigator.userAgent
  const { search } = useLocation()

  const sendData = async (values: Partial<ILead>) => {
    if (!leadContext) {
      throw new Error('Lead context not found')
    }
    values.formDropoff = true
    const searchParams = new URLSearchParams(search)
    values.browserLanguage = browserLang
    values.browserDevice = userAgent
    values.utm = searchParams.get('utm_source') || ''
    try {
      await updateLead({ ...leadContext.lead, ...values } as any)
      setFormDataSent(true)
    } catch (error) {
      console.log('error', error)
    }
  }

  const formik = useFormik({
    initialValues: storageData,
    validationSchema: validationSchema2,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!leadContext) {
        throw new Error('Lead context not found')
      }

      ReactGA.event({
        category: '2nd Page Next Button',
        action: '2nd Page Next Button Clicked',
      })

      values.id = id

      setStorageData((prev) => {
        const newState = {
          ...prev,
          ...values,
        }
        settingDataToSessionStorage(newState)
        return newState
      })
      try {
        await sendData(values)
        navigate(`/${language}/db-page-3/${id}?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
      } catch (ex) {
        console.log(ex)
      }
    },
  })

  const {
    values,
    touched,
    handleSubmit,
    getFieldProps,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formik

  useEffect(() => {
    if (!Object.keys(errors).length) {
      return
    } else if (submitClicked && errors) {
      document.getElementById(Object.keys(errors)[0])?.focus()
      window.scrollTo(
        0,
        -100 +
        (document.getElementById(Object.keys(errors)[0])?.offsetTop || 0),
      )
      setSubmitClicked(false)
    }
  }, [errors, submitClicked])

  useEffect(() => {
    window.scrollTo(0, 0)

    const beforeUnloadHandler = async (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''

      if (sessionStorage.getItem('beforeUnloadPreFill') && !formDataSent) {
        try {
          sendData({
            ...JSON.parse(sessionStorage.getItem('beforeUnloadPreFill') || ''),
            formDropoff: true,
          }).then(() => {
            sessionStorage.removeItem('beforeUnloadPreFill')
            setFormDataSent(true)
          })
        } catch (_) {
          sessionStorage.removeItem('beforeUnloadPreFill')
        }
      }
    }

    window.addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      sessionStorage.removeItem('beforeUnloadPreFill')
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) {
      try {
        formik.values.id = id
        sessionStorage.setItem(
          'beforeUnloadPreFill',
          JSON.stringify(leadContext?.lead),
        )
        sessionStorage.setItem('formData', JSON.stringify(leadContext?.lead))
      } catch (ex) {
        console.log(ex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnblur = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    setFieldValue(name, e.target.value)
  }

  const handleOnChangeAddress = (result: google.maps.places.PlaceResult | null) => {
    if (result) {

      if (result.address_components?.length === 0) {
        setFieldValue('streetAddress', result.adr_address)
        return
      }

      const newValues = {
        streetAddress: '',
      } as {
        city?: string
        stateSelectHome?: string
        streetAddress?: string
        zipCode?: string
      }

      result.address_components?.reverse().forEach((a) => {
        if (a.types.includes('street_number')) {
          newValues.streetAddress = `${a.long_name} ${newValues.streetAddress}`
        }

        if (a.types.includes('route')) {
          newValues.streetAddress = a.long_name
        }

        if (a.types.includes('sublocality')) {
          if (!newValues.city) {
            newValues.city = a.long_name
          }
        }

        if (a.types.includes('locality')) {
          newValues.city = a.long_name
        }

        if (a.types.includes('administrative_area_level_1')) {
          newValues.stateSelectHome = a.long_name
        }

        if (a.types.includes('postal_code')) {
          newValues.zipCode = a.long_name
        }
      })
      setFieldValue('streetAddress', newValues.streetAddress)
      setFieldValue('city', newValues.city)
      setFieldValue('stateSelectHome', newValues.stateSelectHome)
      setFieldValue('zipCode', newValues.zipCode)
    } else {
      setFieldValue('streetAddress', '')
    }
  }

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const value = e?.target?.value

    if (name === 'zipCode') {
      const filteredValue = value.replace(/[^\d]/g, '')
      setFieldValue(name, filteredValue)
      setFieldTouched(name)
    }
    setFieldValue(name, value)
    setFieldTouched(name)
  }

  const handleOnChangeWithString = (value: string, name: string) => {
    setFieldValue(name, value)
    setFieldTouched(name)
  }

  const renderFormBInputs = formBInputsV2.map(
    ({ name, label, maxLength, type, placeholder }) => {
      return (
        <div className="form-field" key={name}>
          <label htmlFor={name}>
            <div className="inputLabels">
              <p className="field">{t(`label.${name}`)}</p>
              {touched[name] && errors[name] && (
                <span className="errorMessage">{t(`error.${errors[name]}`)}</span>
              )}
            </div>
            <input
              id={name}
              type={type}
              maxLength={maxLength || 200}
              {...getFieldProps(name)}
              onChange={(e) => handleOnChange(e, name)}
              onBlur={(e) => handleOnblur(e, name)}
              placeholder={t(`placeholder.${name}`) || ''}
            />
          </label>
        </div>
      )
    },
  )

  const genderListRender = genderList.map((option) => {
    return (
      <option
        key={option.value}
        disabled={option.disabled}
        value={option.value}
      >
        {t(`options.gender.${option.label}`)}
      </option>
    )
  })

  const statesArrayRender = statesArray.map((option) => {
    return (
      <option
        key={option.value}
        disabled={option.disabled}
        value={option.value}
      >
        {t(`options.state.${option.label}`)}
      </option>
    )
  })

  return (
    <form className="formB" onSubmit={handleSubmit}>
      <span className="page-num-indicator" id="page-2-indicator">
        {t('other.page2')}
      </span>
      <div className="form-field">
        <div className="inputLabels">
          <p>{t('label.gender')}</p>
          {touched.gender && errors.gender ? (
            <span className="errorMessage">{t(`error.Gender is required`)}</span>
          ) : (
            <></>
          )}
        </div>
        <label htmlFor="gender">
          <select
            id="gender"
            onChange={handleChange}
            value={getFieldProps('gender').value}
          >
            {genderListRender}
          </select>
        </label>
      </div>

      <DateInput
        value={values.dateOfBirth}
        touched={touched?.dateOfBirth}
        error={errors?.dateOfBirth}
        onChange={(e) => handleOnChangeWithString(e, 'dateOfBirth')}
      />

      <div>
        <GooglePlacesInput setFieldTouched={setFieldTouched} name='streetAddress' touched={touched?.streetAddress} error={errors?.streetAddress} onChange={handleOnChangeAddress} />
      </div>

      {renderFormBInputs}
      <div className="form-field">
        <div className="inputLabels">
          <p>{t('label.stateSelectHome')}</p>
          {touched.stateSelectHome && errors.stateSelectHome ? (
            <span className="errorMessage">{t('error.Select a state')}</span>
          ) : (
            <></>
          )}
        </div>
        <label htmlFor="stateSelectHome">
          <select
            id="stateSelectHome"
            onChange={handleChange}
            value={getFieldProps('stateSelectHome').value}
          >
            {statesArrayRender}
          </select>
        </label>
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => setSubmitClicked(true)}
      >
        {t('other.next')}
      </button>
    </form>
  )
}
