import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dbLogo from '../../images/db-logo.png'
import appStoreLogo from '../../images/appStore-logo.jpeg'
import googlePlayLogo from '../../images/googlePlay-logo.jpeg'
import { useTranslation } from 'react-i18next'

export const ThankYou = () => {
  // ============== [ Has Component fully rendered once Flag ] ============== //
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    const beforeUnloadHandler = async () => {
      sessionStorage.setItem('redirectFlag', 'true')
    }
    window.addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem('redirectFlag') === 'true') {
      sessionStorage.removeItem('redirectFlag')
      navigate('/')
    }
  })

  return (
    <div className="thankYouPage">
      <div className="thankYou-dbLogo">
        <img src={dbLogo} alt="Drivers Benefits Logo" id="db-logo-thankyou" />
      </div>

    {/*  <div className="thankYou-marketplace-links">
        <p>{t('other.fasterAccess')}</p>
        <div className="appStores-container">
          <div className="appleStore-link-container">
            <a
              href="https://apps.apple.com/us/app/drivers-benefits/id1417480411?l"
              target="_blank" rel="noreferrer"
            >
              <img
                src={appStoreLogo}
                alt="Apple App Store Logo"
                id="appStore-logo"
              />
            </a>
          </div>
          <div className="playStore-link-container">
            <a
              href="https://play.google.com/store/apps/details?id=com.driversbenefits"
              target="_blank" rel="noreferrer"
            >
              <img
                src={googlePlayLogo}
                alt="Google Playstore Logo"
                id="googlePlay-logo"
              />
            </a>
          </div>
        </div>
      </div>*/}
      <div className="thankYou-last">
        <h2>{t('other.thankYou')}</h2>
        <p>
          {t('other.emailYouSoon')}
        </p>
      </div>
    </div>
  )
}
