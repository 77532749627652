import { Header } from '../components/header'
import { FormB } from '../../react-formik-v1/components/form-b-formik'
import { FormBV2 } from '../../react-formik-v2/components/form-b-formik'
import { IFormProps } from '../utils/interfaces'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


export const SecondScreen = ({ storageData, setStorageData, language }: IFormProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {

    if (storageData.email !== '' ) {

    } else {
      navigate(`/?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
    }



  }, [storageData])

  return (
    <>
      <Header />
      <div className="form-section home-screen">
        <div className="form-container">
       {/*   {localStorage.getItem('formVariant') === 'BCF-React-Lucy' ? (
            <FormB {...{ storageData, setStorageData, language }} />
          ) : (*/}
            <FormBV2 {...{ storageData, setStorageData, language }} />
       {/*   )}*/}
        </div>
      </div>
    </>
  )
}
