import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as langFiles from './resources'

const resources = Object.entries(langFiles).reduce((a, [key, file]) => {
    a[key] = {
      translation: file,
    }
    return a
  }, {} as any)

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    resources,
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;