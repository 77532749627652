import { useState } from 'react'
import { healthcareRadioInputs } from '../../utils/formInput-values'
import { useTranslation } from 'react-i18next'

export const HealthcareComponent = (props: any): any => {
  const { t } = useTranslation()
  const { error, onChange } = props
  const [hasHealthcare, setHasHealthcare] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<number>()
  const [isActive2, setIsActive2] = useState<number>()
  const [isActive3, setIsActive3] = useState<number>()
  const touchProp = props?.touched[0]

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: string,
  ) => {
    const { value } = e.target
    if (name === 'doYouHaveHealthInsurance' && value === 'Yes') {
      setHasHealthcare(!hasHealthcare)
    } else if (name === 'doYouHaveHealthInsurance' && value !== 'Yes') {
      setHasHealthcare(false)
    }
    onChange(value, name)
  }

  const getError = (nameInput: string, errors: []) => {
    let errorMessage
    console.log('errors', errors)
    console.log('touchProp', touchProp)
    errors?.map((soloError: any) => {
      if (soloError.name === nameInput && soloError.error) {
        errorMessage = t(`error.${soloError.error}`)
      }
    })
    return errorMessage
  }

  const handleOnClick = (num: number) => {
    setIsActive(num)
  }

  const handleOnClick2 = (num: number) => {
    setIsActive2(num)
  }

  const handleOnClick3 = (num: number) => {
    setIsActive3(num)
  }

  const renderHealthcareRadioInputs = healthcareRadioInputs.map((input) => {
    const { label, name, type, radioValue, id } = input
    if (name === 'doYouHaveHealthInsurance') {
      return (
        <div className="form-field" key={name}>
          <div className="inputLabels">
            <p id={`${name}-label`}>{t('label.doYouHaveHealthInsurance')}</p>
          </div>
          <label htmlFor={name}>
            {touchProp && !hasHealthcare && getError(name, error) ? (
              <span id={name} className="errorMessage healthCareErrors">
                {getError(name, error)}
              </span>
            ) : (
              <></>
            )}
          </label>
          <div className="radio-buttons-container">
            <div
              className={
                'radio-button ' +
                (isActive === 0 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[0]}
                value={radioValue[0]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick(0)}
              />
              <label htmlFor={id[0]} id={`${id[0]}-label`}>
                <span>{t(`options.insurance.${radioValue[0]}`)}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive === 1 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[1]}
                value={radioValue[1]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick(1)}
              />
              <label htmlFor={id[1]} id={`${id[1]}-label`}>
                <span>{t(`options.insurance.${radioValue[1]}`)}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive === 2 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[2]}
                value={radioValue[2]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick(2)}
              />
              <label htmlFor={id[2]} id={`${id[2]}-label`}>
                <span>{t(`options.insurance.${radioValue[2]}`)}</span>
              </label>
            </div>
          </div>
        </div>
      )
    } else if (name === 'coverageThroughTheState' && hasHealthcare) {
      return (
        <div className="form-field" key={name}>
          <div className="inputLabels">
            <p id={`${name}-label`}> {label}</p>
          </div>
          <label htmlFor={name}>
            {hasHealthcare && error && getError(name, error) ? (
              <span id={name} className="errorMessage healthCareErrors">
                {getError(name, error)}
              </span>
            ) : (
              <></>
            )}
          </label>
          <div className="radio-buttons-container">
            <div
              className={
                'radio-button ' +
                (isActive2 === 0 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[0]}
                value={radioValue[0]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick2(0)}
              />
              <label htmlFor={id[0]} id={`${id[0]}-label`}>
                <span>{radioValue[0]}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive2 === 1 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[1]}
                value={radioValue[1]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick2(1)}
              />
              <label htmlFor={id[1]} id={`${id[1]}-label`}>
                <span>{radioValue[1]}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive2 === 2 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[2]}
                value={radioValue[2]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick2(2)}
              />
              <label htmlFor={id[2]} id={`${id[2]}-label`}>
                <span>{radioValue[2]}</span>
              </label>
            </div>
          </div>
        </div>
      )
    } else if (name === 'payMoreThan20' && hasHealthcare) {
      return (
        <div className="form-field" key={name}>
          <div className="inputLabels">
            <p id={`${name}-label`}> {label}</p>
          </div>
          <label htmlFor={name}>
            {hasHealthcare && error && getError(name, error) ? (
              <span id={name} className="errorMessage healthCareErrors">
                {getError(name, error)}
              </span>
            ) : (
              <></>
            )}
          </label>
          <div className="radio-buttons-container">
            <div
              className={
                'radio-button ' +
                (isActive3 === 0 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[0]}
                value={radioValue[0]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick3(0)}
              />
              <label htmlFor={id[0]} id={`${id[0]}-label`}>
                <span>{radioValue[0]}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive3 === 1 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[1]}
                value={radioValue[1]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick3(1)}
              />
              <label htmlFor={id[1]} id={`${id[1]}-label`}>
                <span>{radioValue[1]}</span>
              </label>
            </div>
            <div
              className={
                'radio-button ' +
                (isActive3 === 2 ? 'checkedBox' : 'uncheckedBox')
              }
            >
              <input
                type={type}
                name={name}
                id={id[2]}
                value={radioValue[2]}
                onChange={(e) => handleChange(e, name)}
                onClick={() => handleOnClick3(2)}
              />
              <label htmlFor={id[2]} id={`${id[2]}-label`}>
                <span>{radioValue[2]}</span>
              </label>
            </div>
          </div>
        </div>
      )
    }
  })
  return renderHealthcareRadioInputs
}
