import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'


const getInitialValue = (fieldValue: string = '') => {
  if (fieldValue) {
    const date = dayjs(fieldValue, 'YYYY-mm-DD')

    return {
      month: String(date.month() + 1),
      day: String(date.date()),
      year: String(date.year()),
    }
  } else {
    return {
      month: '',
      day: '',
      year: '',
    }
  }
}

interface DateInputProps {
  value?: string
  touched?: boolean
  error?: string
  onChange: (value: string) => void
}

const DateInput = (props: DateInputProps) => {
  const { t } = useTranslation()
  const { 
    value: fieldValue, 
    touched,
    error,
    onChange: setFieldValue  
  } = props

  const [value, setValue] = useState<{
    month: string
    day: string
    year: string
  }>(getInitialValue(fieldValue))
  const [strictError, setStrictError] = useState<string[]>([])

  useEffect(() => {
    const { month, day, year } = value

    const valueForFormik = `${year}-${month}-${day}`

    if (+month > 12 || +day > 31) {
      setFieldValue('Invalid date')
      return
    }

    if (valueForFormik !== '--') {
      setFieldValue(valueForFormik)
    }
  }, [value])

  const checkingForStrictErrors = (name: string, value: string) => {
    const removeStrictErrorItem = (name: string) => {
      const findIndex = strictError.findIndex((e) => e === name)
      if (findIndex > -1) {
        const newErrorValue = strictError.slice()
        newErrorValue.splice(findIndex, 1)
        setStrictError(newErrorValue)
      }
    }

    if (name === 'month' || name === 'day') {
      const max = name === 'month' ? 12 : 31
      if (value.length === 2 && (Number(value) > max || value === '00')) {
        setStrictError([...strictError, name])
      } else {
        removeStrictErrorItem(name)
      }
    }

    if (name === 'year') {
      const currentYear = dayjs().year()
      if (
        value.length === 4 &&
        (Number(value) > currentYear - 16 || Number(value) < currentYear - 100)
      ) {
        setStrictError([...strictError, name])
      } else {
        removeStrictErrorItem(name)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: currentValue } = event.currentTarget

    const filteredValue = currentValue.replace(/[^0-9]/g, '')

    checkingForStrictErrors(name, currentValue)

    setValue({
      ...value,
      [name]: filteredValue,
    })
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value: currentValue } = event.currentTarget

    if (currentValue.length === 1 && currentValue !== '0') {
      setValue({
        ...value,
        [name]: `0${currentValue}`,
      })
    }
  }

  return (
    <div className="form-field">
        <label htmlFor="dateOfBirth">
          <div className="inputLabels">
            <p className="field">{t('label.dateOfBirth')}</p>
            {touched && error && (
              <span className="errorMessage">{t(`error.${error}`)}</span>
            )}
          </div>
        
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          rowGap: '10px',
          columnGap: '10px'
        }}>
          <input
            className="date-picker-input"
            name="month"
            placeholder={t('label.mm') || ''}
            maxLength={2}
            value={value.month}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <input
            className="date-picker-input"
            name="day"
            placeholder={t('label.dd') || ''}
            maxLength={2}
            value={value.day}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <input
            className="date-picker-input"
            name="year"
            placeholder={t('label.yyyy') || ''}
            maxLength={4}
            value={value.year}
            onChange={handleChange}
          />
        </div>

        </label>
    </div>
  )
}

export default DateInput
