/* eslint-disable import/no-anonymous-default-export */
export default {
  label: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    preferredLanguage: 'Preferred Language',
    carCompanies: 'Which Black Car Company(s) do you work for?',
    gender: 'Gender',
    dateOfBirth: 'Date Of Birth',
    streetAddress: 'Street Address',
    city: 'City',
    stateSelectHome: 'State/Province',
    zipCode: 'Zip Code',
    driversLicenseNumber: "Driver's License #",
    driversLicenseNumberСonfirm: 'Re-Enter DL #',
    tlcNumber: 'TLC License #',
    dlConfirm: 'Re-Enter TLC License #',
    whatStateDoYouDriveIn: 'What state do you drive in',
    doYouHaveHealthInsurance: 'Do you currently have health insurance?',
    mm: 'MM',
    dd: 'DD',
    yyyy: 'YYYY',
  },
  placeholder: {
    firstName: 'Enter Your Name',
    lastName: 'Enter Your Last Name',
    email: 'example@domain-name.com',
    phoneNumber: '(###) ###-####',
    gender: 'Select Gender',
    streetAddress: 'Enter Your Street Address',
    city: 'Enter City',
    stateSelectHome: 'Select State',
    zipCode: 'Enter Your Zip Code',
    driversLicenseNumber: "Enter Your Driver's License",
    driversLicenseNumberСonfirm: 'Re-Enter DL #',
    tlcNumber: 'Enter Your TLC #',
    tlcNumberСonfirm: 'Re-Enter TLC #',
    other: 'Other',
  },
  options: {
    preferredLanguage: {
      'Select Language': 'Select Language',
      'Arabic': 'Arabic',
      'English': 'English',
      'Bengali': 'Bengali',
      'Chinese': 'Chinese',
      'French': 'French',
      'Hindi': 'Hindi',
      'Punjabi': 'Punjabi',
      'Russian': 'Russian',
      'Spanish': 'Spanish',
      'Urdu': 'Urdu',
      'Other': 'Other',
      'Abkhazian': 'Abkhazian',
      'Afar': 'Afar',
      'Afrikaans': 'Afrikaans',
      'Akan': 'Akan',
      'Albanian': 'Albanian',
      'Amharic': 'Amharic',
      'Arabic': 'Arabic',
      'Aragonese': 'Aragonese',
      'Armenian': 'Armenian',
      'Assamese': 'Assamese',
      'Avaric': 'Avaric',
      'Avestan': 'Avestan',
      'Aymara': 'Aymara',
      'Azerbaijani': 'Azerbaijani',
      'Bambara': 'Bambara',
      'Bashkir': 'Bashkir',
      'Basque': 'Basque',
      'Belarusian': 'Belarusian',
      'Bengali': 'Bengali',
      'Bislama': 'Bislama',
      'Bosnian': 'Bosnian',
      'Breton': 'Breton',
      'Bulgarian': 'Bulgarian',
      'Burmese': 'Burmese',
      'Catalan, Valencian': 'Catalan, Valencian',
      'Chamorro': 'Chamorro',
      'Chechen': 'Chechen',
      'Chichewa, Chewa, Nyanja': 'Chichewa, Chewa, Nyanja',
      'Chinese': 'Chinese',
      'Church Slavonic, Old Slavonic, Old Church Slavonic': 'Church Slavonic, Old Slavonic, Old Church Slavonic',
      'Chuvash': 'Chuvash',
      'Cornish': 'Cornish',
      'Corsican': 'Corsican',
      'Cree': 'Cree',
      'Croatian': 'Croatian',
      'Czech': 'Czech',
      'Danish': 'Danish',
      'Divehi, Dhivehi, Maldivian': 'Divehi, Dhivehi, Maldivian',
      'Dutch, Flemish': 'Dutch, Flemish',
      'Dzongkha': 'Dzongkha',
      'English': 'English',
      'Esperanto': 'Esperanto',
      'Estonian': 'Estonian',
      'Ewe': 'Ewe',
      'Faroese': 'Faroese',
      'Fijian': 'Fijian',
      'Finnish': 'Finnish',
      'French': 'French',
      'Western Frisian': 'Western Frisian',
      'Fulah': 'Fulah',
      'Gaelic, Scottish Gaelic': 'Gaelic, Scottish Gaelic',
      'Galician': 'Galician',
      'Ganda': 'Ganda',
      'Georgian': 'Georgian',
      'German': 'German',
      'Greek': 'Greek',
      'Kalaallisut, Greenlandic': 'Kalaallisut, Greenlandic',
      'Guarani': 'Guarani',
      'Gujarati': 'Gujarati',
      'Haitian, Haitian Creole': 'Haitian, Haitian Creole',
      'Hausa': 'Hausa',
      'Hebrew': 'Hebrew',
      'Herero': 'Herero',
      'Hindi': 'Hindi',
      'Hiri Motu': 'Hiri Motu',
      'Hungarian': 'Hungarian',
      'Icelandic': 'Icelandic',
      'Ido': 'Ido',
      'Igbo': 'Igbo',
      'Indonesian': 'Indonesian',
      'Interlingua': 'Interlingua',
      'Interlingue, Occidental': 'Interlingue, Occidental',
      'Inuktitut': 'Inuktitut',
      'Inupiaq': 'Inupiaq',
      'Irish': 'Irish',
      'Italian': 'Italian',
      'Japanese': 'Japanese',
      'Javanese': 'Javanese',
      'Kannada': 'Kannada',
      'Kanuri': 'Kanuri',
      'Kashmiri': 'Kashmiri',
      'Kazakh': 'Kazakh',
      'Central Khmer': 'Central Khmer',
      'Kikuyu, Gikuyu': 'Kikuyu, Gikuyu',
      'Kinyarwanda': 'Kinyarwanda',
      'Kirghiz, Kyrgyz': 'Kirghiz, Kyrgyz',
      'Komi': 'Komi',
      'Kongo': 'Kongo',
      'Korean': 'Korean',
      'Kuanyama, Kwanyama': 'Kuanyama, Kwanyama',
      'Kurdish': 'Kurdish',
      'Lao': 'Lao',
      'Latin': 'Latin',
      'Latvian': 'Latvian',
      'Limburgan, Limburger, Limburgish': 'Limburgan, Limburger, Limburgish',
      'Lingala': 'Lingala',
      'Lithuanian': 'Lithuanian',
      'Luba-Katanga': 'Luba-Katanga',
      'Luxembourgish, Letzeburgesch': 'Luxembourgish, Letzeburgesch',
      'Macedonian': 'Macedonian',
      'Malagasy': 'Malagasy',
      'Malay': 'Malay',
      'Malayalam': 'Malayalam',
      'Maltese': 'Maltese',
      'Manx': 'Manx',
      'Maori': 'Maori',
      'Marathi': 'Marathi',
      'Marshallese': 'Marshallese',
      'Mongolian': 'Mongolian',
      'Nauru': 'Nauru',
      'Navajo, Navaho': 'Navajo, Navaho',
      'North Ndebele': 'North Ndebele',
      'South Ndebele': 'South Ndebele',
      'Ndonga': 'Ndonga',
      'Nepali': 'Nepali',
      'Norwegian': 'Norwegian',
      'Norwegian Bokmål': 'Norwegian Bokmål',
      'Norwegian Nynorsk': 'Norwegian Nynorsk',
      'Sichuan Yi, Nuosu': 'Sichuan Yi, Nuosu',
      'Occitan': 'Occitan',
      'Ojibwa': 'Ojibwa',
      'Oriya': 'Oriya',
      'Oromo': 'Oromo',
      'Ossetian, Ossetic': 'Ossetian, Ossetic',
      'Pali': 'Pali',
      'Pashto, Pushto': 'Pashto, Pushto',
      'Persian': 'Persian',
      'Polish': 'Polish',
      'Portuguese': 'Portuguese',
      'Punjabi, Panjabi': 'Punjabi, Panjabi',
      'Quechua': 'Quechua',
      'Romanian, Moldavian, Moldovan': 'Romanian, Moldavian, Moldovan',
      'Romansh': 'Romansh',
      'Rundi': 'Rundi',
      'Russian': 'Russian',
      'Northern Sami': 'Northern Sami',
      'Samoan': 'Samoan',
      'Sango': 'Sango',
      'Sanskrit': 'Sanskrit',
      'Sardinian': 'Sardinian',
      'Serbian': 'Serbian',
      'Shona': 'Shona',
      'Sindhi': 'Sindhi',
      'Sinhala, Sinhalese': 'Sinhala, Sinhalese',
      'Slovak': 'Slovak',
      'Slovenian': 'Slovenian',
      'Somali': 'Somali',
      'Southern Sotho': 'Southern Sotho',
      'Spanish': 'Spanish',
      'Sundanese': 'Sundanese',
      'Swahili': 'Swahili',
      'Swati': 'Swati',
      'Swedish': 'Swedish',
      'Tagalog': 'Tagalog',
      'Tahitian': 'Tahitian',
      'Tajik': 'Tajik',
      'Tamil': 'Tamil',
      'Tatar': 'Tatar',
      'Telugu': 'Telugu',
      'Thai': 'Thai',
      'Tibetan': 'Tibetan',
      'Tigrinya': 'Tigrinya',
      'Tonga': 'Tonga',
      'Tsonga': 'Tsonga',
      'Tswana': 'Tswana',
      'Turkish': 'Turkish',
      'Turkmen': 'Turkmen',
      'Twi': 'Twi',
      'Uighur, Uyghur': 'Uighur, Uyghur',
      'Ukrainian': 'Ukrainian',
      'Urdu': 'Urdu',
      'Uzbek': 'Uzbek',
      'Venda': 'Venda',
      'Vietnamese': 'Vietnamese',
      'Volapük': 'Volapük',
      'Walloon': 'Walloon',
      'Welsh': 'Welsh',
      'Wolof': 'Wolof',
      'Xhosa': 'Xhosa',
      'Yiddish': 'Yiddish',
      'Yoruba': 'Yoruba',
      'Zhuang, Chuang': 'Zhuang, Chuang',
      'Zulu': 'Zulu',
    },
    carCompanies: {
      uber: 'Uber',
      lyft: 'Lyft',
      vital: 'Vital',
      agape: 'Agape',
      other: 'Other',
    },
    gender: {
      Male: 'Male',
      Female: 'Female',
      'Prefer not to say': 'Prefer not to say',
      'Select Gender': 'Select Gender',
    },
    state: {
      'Select State / Province': 'Select State / Province',
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    insurance: { Yes: 'Yes', No: 'No', Unsure: 'Unsure' },
  },
  error: {
    'First name is required': 'First name is required',
    'Last name is required': 'Last name is required',
    'Email is required': 'Email is required',
    'Phone Number is required': 'Phone Number is required',
    'Invalid Phone Number': 'Invalid Phone Number',
    'Invalid email': 'Invalid email',
    'Name too short': 'Name too short',
    'Gender is required': 'Gender is required',
    'Date of Birth is required': 'Date of Birth is required',
    'City is required': 'City is required',
    'Select a state': 'Select a state',
    'Address is required': 'Address is required',
    'Zip code is required': 'Zip code is required',
    'Invalid Age': 'Invalid Age',
    'Address too short': 'Address too short',
    'Invalid address': 'Invalid address',
    'Invalid city': 'Invalid city',
    'City too short': 'City too short',
    'Zip code not valid': 'Zip code not valid',
    'Zip code too short': 'Zip code too short',
    'CompanyName': 'Must Provide At Least ONE Car Company Name',
    'Must Provide At Least ONE Car Company Name': 'Must Provide At Least ONE Car Company Name',
    'Must Select One': 'Must Select One',
    'Language is required': 'Language is required',
    'TLC License # required': 'TLC License # required',
    'TLC # must match': 'TLC # must match',
    'streetAddress': 'Street Address is required',
  },
  other: {
    page1: 'Page 1 of 3',
    page2: 'Page 2 of 3',
    page3: 'Page 3 of 3',
    mainTitle: 'Activate Your Benefits',
    eligleDrivers: 'All eligible drivers of NY Black Car Services including Lyft, Uber, and the more traditional Black Car bases in the industry can sign up, for free, to receive a valuable package of Drivers Benefits, provided by The Black Car Fund and the Independent Drivers Guild!',
    compleInfo:
      'Complete the information below to activate your Drivers Benefits. Or call us at',
    hours: 'from \nMon-Fri 8:00 am-8:00 pm.',
    next: 'Next',
    submit: 'Submit',
    info: "Don't have a TLC License number? Enter your driver's license number if you don't drive in New York City.",
    link: "Don't know what TLC is?",
    here: 'here',
    byCompleteInfo: 'By completing the information above and clicking “Submit,” you confirm that you have read, understand, and agree to be bound by our Terms of Use, which can be found',
    youAlso: 'You also agree that we may use and disclose your information as set forth in our Privacy Policy, which can be found',
    facts: 'Facts you should know',
    asYouMayKnow: "As you may know, the Black Car Fund provides drivers in the black car industry with workers' compensation benefits. In order to help its covered drivers stay healthy and reduce accidents that lead to injuries the Black Car Fund is sponsoring this Program for the purposes of injury and medical claims reduction. The Program is provided to you as a driver covered by the Black Car Fund and is not intended to provide you with any rights of employment or to otherwise affect your status as an independent contractor with respect to the Black Car Fund or with the dispatch base you are affiliated with. While we hope you will participate in the Program, the Program is entirely voluntary and you are not required to participate. If you are an employee driver, this Program is not sponsored or endorsed by your employer. The Program is not intended to be covered by the Employee Retirement Income Security Act (ERISA) or the Patient Protection and Affordable Care Act ('PPACA'). If you register for the telemedicine benefits available under the Program, you may become ineligible to contribute to a health savings account. Consult with your tax advisor for more information. The Black Car Fund reserves the right to revise or terminate this Program in whole or in part at any time, and for any reason.",
    questions: 'Questions?',
    callUs: 'Call us Mon-Fri from 8:00 a.m.-8:00 p.m. ET. at',
    fasterAccess: 'For faster access, download our mobile app:',
    thankYou: 'Thank you!',
    emailYouSoon: "We'll email you soon about how to use your new benefits. Make sure to check your spam folder.",
    clickHere: "Click here to see what Benefits you'll be able to access!"
  },
  systemMessages: {
    blocking: {
      title: 'Temporarily Unavailable',
    },
  },
}
