import { Header } from '../components/header'
import { FormCV2 } from '../../react-formik-v2/components/form-c-formik'
import { IFormProps } from '../utils/interfaces'
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const SubmitScreen = ({
  setStorageData,
  storageData,
  language
}: IFormProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();


  useEffect(() => {

    if (storageData.email !== '' ) {

    } else {
      navigate(`/?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
    }


  }, [storageData])


  return (
    <>
      <Header />
      <div className="form-section submit-screen">
        <div className="form-container">
        {/*  {localStorage.getItem('formVariant') === 'BCF-React-Lucy' ? (
            <FormC {...{ storageData, setStorageData, language }} />
          ) : (*/}
            <FormCV2 {...{ storageData, setStorageData, language }} />
       {/*   )}*/}
        </div>
      </div>
    </>
  )
}
