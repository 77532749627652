/* eslint-disable import/no-anonymous-default-export */
export default {
  label: {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    preferredLanguage: 'Langue préférée',
    carCompanies: 'Pour quelle(s) entreprise(s) de voiture noire travaillez-vous?',
    gender: 'Genre',
    dateOfBirth: 'Date de naissance',
    streetAddress: 'Adresse',
    city: 'Ville',
    stateSelectHome: 'État/Province',
    zipCode: 'Code postal',
    driversLicenseNumber: 'Numéro de permis de conduire',
    driversLicenseNumberСonfirm: 'Confirmez le numéro de permis de conduire',
    tlcNumber: 'Numéro de licence TLC',
    dlConfirm: 'Confirmez le numéro de licence TLC',
    whatStateDoYouDriveIn: 'Dans quel État conduisez-vous ?',
    doYouHaveHealthInsurance: 'Avez-vous actuellement une assurance santé?',
    mm: 'MM',
    dd: 'DD',
    yyyy: 'AAAA',
  },
  placeholder: {
    firstName: 'Entrez votre nom',
    lastName: 'Entrez votre nom de famille',
    email: 'exemple@nom-de-domaine.com',
    phoneNumber: '(###) ###-####',
    gender: 'Sélectionnez le genre',
    streetAddress: 'Entrez votre adresse',
    city: 'Entrez votre ville',
    stateSelectHome: "Sélectionnez l'état",
    zipCode: 'Entrez votre code postal',
    driversLicenseNumber: 'Entrez votre numéro de permis de conduire',
    driversLicenseNumberСonfirm: 'Ré-entrez votre numéro de permis de conduire',
    tlcNumber: 'Entrez votre numéro TLC',
    tlcNumberСonfirm: 'Ré-entrez votre numéro TLC',
    other: 'Autre',
  },
  options: {
    preferredLanguage: {
      'Select Language': 'Sélectionnez une langue',
      'Arabic': 'Arabe',
      'English': 'Anglais',
      'Bengali': 'Bengali',
      'Chinese': 'Chinois',
      'French': 'Français',
      'Hindi': 'Hindi',
      'Punjabi': 'Pendjabi',
      'Russian': 'Russe',
      'Spanish': 'Espagnol',
      'Urdu': 'Ourdou',
      'Other': 'Autre',
      'Abkhazian': 'Abkhaze',
      'Afar': 'Afar',
      'Afrikaans': 'Afrikaans',
      'Akan': 'Akan',
      'Albanian': 'Albanais',
      'Amharic': 'Amharique',
      'Aragonese': 'Aragonais',
      'Armenian': 'Arménien',
      'Assamese': 'Assamais',
      'Avaric': 'Avar',
      'Avestan': 'Avestique',
      'Aymara': 'Aymara',
      'Azerbaijani': 'Azéri',
      'Bambara': 'Bambara',
      'Bashkir': 'Bachkir',
      'Basque': 'Basque',
      'Belarusian': 'Biélorusse',
      'Bislama': 'Bichelamar',
      'Bosnian': 'Bosniaque',
      'Breton': 'Breton',
      'Bulgarian': 'Bulgare',
      'Burmese': 'Birman',
      'Catalan, Valencian': 'Catalan, Valencien',
      'Chamorro': 'Chamorro',
      'Chechen': 'Tchétchène',
      'Chichewa, Chewa, Nyanja': 'Chichewa, Chewa, Nyanja',
      'Church Slavonic, Old Slavonic, Old Church Slavonic': "Slavon d'Église, Vieux Slavon, Ancien Slavon d'Église",
      'Chuvash': 'Tchouvache',
      'Cornish': 'Cornique',
      'Corsican': 'Corse',
      'Cree': 'Cree',
      'Croatian': 'Croate',
      'Czech': 'Tchèque',
      'Danish': 'Danois',
      'Divehi, Dhivehi, Maldivian': 'Divehi, Dhivehi, Maldivien',
      'Dutch, Flemish': 'Néerlandais, Flamand',
      'Dzongkha': 'Dzongkha',
      'English': 'Anglais',
      'Esperanto': 'Espéranto',
      'Estonian': 'Estonien',
      'Ewe': 'Ewe',
      'Faroese': 'Féroïen',
      'Fijian': 'Fidjien',
      'Finnish': 'Finnois',
      'French': 'Français',
      'Western Frisian': 'Frison occidental',
      'Fulah': 'Peul',
      'Gaelic, Scottish Gaelic': 'Gaélique, Gaélique écossais',
      'Galician': 'Galicien',
      'Ganda': 'Ganda',
      'Georgian': 'Géorgien',
      'German': 'Allemand',
      'Greek': 'Grec',
      'Kalaallisut, Greenlandic': 'Kalaallisut, Groenlandais',
      'Guarani': 'Guarani',
      'Gujarati': 'Gujarati',
      'Haitian, Haitian Creole': 'Haïtien, Créole haïtien',
      'Hausa': 'Haoussa',
      'Hebrew': 'Hébreu',
      'Herero': 'Herero',
      'Hindi': 'Hindi',
      'Hiri Motu': 'Hiri Motu',
      'Hungarian': 'Hongrois',
      'Icelandic': 'Islandais',
      'Ido': 'Ido',
      'Igbo': 'Igbo',
      'Indonesian': 'Indonésien',
      'Interlingua': 'Interlingua',
      'Interlingue, Occidental': 'Interlingue, Occidental',
      'Inuktitut': 'Inuktitut',
      'Inupiaq': 'Inupiaq',
      'Irish': 'Irlandais',
      'Italian': 'Italien',
      'Japanese': 'Japonais',
      'Javanese': 'Javanais',
      'Kannada': 'Kannada',
      'Kanuri': 'Kanuri',
      'Kashmiri': 'Kashmiri',
      'Kazakh': 'Kazakh',
      'Central Khmer': 'Khmer central',
      'Kikuyu, Gikuyu': 'Kikuyu, Gikuyu',
      'Kinyarwanda': 'Kinyarwanda',
      'Kirghiz, Kyrgyz': 'Kirghize, Kirghiz',
      'Komi': 'Komi',
      'Kongo': 'Kongo',
      'Korean': 'Coréen',
      'Kuanyama, Kwanyama': 'Kuanyama, Kwanyama',
      'Kurdish': 'Kurde',
      'Lao': 'Lao',
      'Latin': 'Latin',
      'Latvian': 'Letton',
      'Limburgan, Limburger, Limburgish': 'Limbourgeois, Limburger, Limburgish',
      'Lingala': 'Lingala',
      'Lithuanian': 'Lituanien',
      'Luba-Katanga': 'Luba-Katanga',
      'Luxembourgish, Letzeburgesch': 'Luxembourgeois, Luxembourgeois',
      'Macedonian': 'Macédonien',
      'Malagasy': 'Malgache',
      'Malay': 'Malais',
      'Malayalam': 'Malayalam',
      'Maltese': 'Maltais',
      'Manx': 'Manx',
      'Maori': 'Maori',
      'Marathi': 'Marathi',
      'Marshallese': 'Marshalais',
      'Mongolian': 'Mongol',
      'Nauru': 'Nauruan',
      'Navajo, Navaho': 'Navajo, Navaho',
      'North Ndebele': 'Ndebele du Nord',
      'South Ndebele': 'Ndebele du Sud',
      'Ndonga': 'Ndonga',
      'Nepali': 'Népalais',
      'Norwegian': 'Norvégien',
      'Norwegian Bokmål': 'Norvégien Bokmål',
      'Norwegian Nynorsk': 'Norvégien Nynorsk',
      'Sichuan Yi, Nuosu': 'Sichuan Yi, Nuosu',
      'Occitan': 'Occitan',
      'Ojibwa': 'Ojibwa',
      'Oriya': 'Oriya',
      'Oromo': 'Oromo',
      'Ossetian, Ossetic': 'Ossète, Ossete',
      'Pali': 'Pali',
      'Pashto, Pushto': 'Pashto, Pushto',
      'Persian': 'Persan',
      'Polish': 'Polonais',
      'Portuguese': 'Portugais',
      'Punjabi, Panjabi': 'Pendjabi, Panjabi',
      'Quechua': 'Quechua',
      'Romanian, Moldavian, Moldovan': 'Roumain, Moldave',
      'Romansh': 'Romanche',
      'Rundi': 'Rundi',
      'Russian': 'Russe',
      'Northern Sami': 'Same du Nord',
      'Samoan': 'Samoan',
      'Sango': 'Sango',
      'Sanskrit': 'Sanskrit',
      'Sardinian': 'Sarde',
      'Serbian': 'Serbe',
      'Shona': 'Shona',
      'Sindhi': 'Sindhi',
      'Sinhala, Sinhalese': 'Sinhala',
      'Slovak': 'Slovaque',
      'Slovenian': 'Slovène',
      'Somali': 'Somali',
      'Southern Sotho': 'Sotho du Sud',
      'Spanish': 'Espagnol',
      'Sundanese': 'Soundanais',
      'Swahili': 'Swahili',
      'Swati': 'Swati',
      'Swedish': 'Suédois',
      'Tagalog': 'Tagalog',
      'Tahitian': 'Tahitien',
      'Tajik': 'Tadjik',
      'Tamil': 'Tamoul',
      'Tatar': 'Tatar',
      'Telugu': 'Télougou',
      'Thai': 'Thaï',
      'Tibetan': 'Tibétain',
      'Tigrinya': 'Tigrigna',
      'Tonga': 'Tongan',
      'Tsonga': 'Tsonga',
      'Tswana': 'Tswana',
      'Turkish': 'Turc',
      'Turkmen': 'Turkmène',
      'Twi': 'Twi',
      'Uighur, Uyghur': 'Ouïghour',
      'Ukrainian': 'Ukrainien',
      'Urdu': 'Ourdou',
      'Uzbek': 'Ouzbek',
      'Venda': 'Venda',
      'Vietnamese': 'Vietnamien',
      'Volapük': 'Volapük',
      'Walloon': 'Wallon',
      'Welsh': 'Gallois',
      'Wolof': 'Wolof',
      'Xhosa': 'Xhosa',
      'Yiddish': 'Yiddish',
      'Yoruba': 'Yoruba',
      'Zhuang, Chuang': 'Zhuang, Chuang',
      'Zulu': 'Zoulou',
    },
    carCompanies: {
      uber: 'Uber',
      lyft: 'Lyft',
      vital: 'Vital',
      agape: 'Agape',
      other: 'Autre',
    },
    gender: {
      Male: 'Masculin',
      Female: 'Féminin',
      'Prefer not to say': 'Préfère ne pas dire',
      'Select Gender': 'Sélectionner le genre',
    },
    state: {
      'Select State / Province': "Sélectionnez l'État / Province",
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvanie',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'Californie',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District de Columbia',
      Florida: 'Floride',
      Georgia: 'Géorgie',
      Hawaii: 'Hawaï',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiane',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'Nouveau-Mexique',
      'North Carolina': 'Caroline du Nord',
      'North Dakota': 'Dakota du Nord',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'Caroline du Sud',
      'South Dakota': 'Dakota du Sud',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginie',
      Washington: 'Washington',
      'West Virginia': 'Virginie-Occidentale',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    insurance: { Yes: 'Oui', No: 'Non', Unsure: 'Incertain' },
  },
  error: {
    'First name is required': 'Le prénom est requis',
    'Last name is required': 'Le nom de famille est requis',
    'Email is required': "L'email est requis",
    'Phone Number is required': 'Le numéro de téléphone est requis',
    'Invalid Phone Number': 'Numéro de téléphone invalide',
    'Invalid email': 'Email invalide',
    'Name too short': 'Nom trop court',
    'Gender is required': 'Le genre est requis',
    'Date of Birth is required': 'La date de naissance est requise',
    'City is required': 'La ville est requise',
    'Select a state': 'Sélectionner un état',
    'Address is required': "L'adresse est requise",
    'Zip code is required': 'Le code postal est requis',
    'Invalid Age': 'Âge invalide',
    'Address too short': 'Adresse trop courte',
    'Invalid address':'Adresse invalide',
    'Invalid city':'Ville invalide',
    'City too short': 'Ville trop courte',
    'Zip code not valid': 'Code postal non valide',
    'Zip code too short': 'Code postal trop court',
    'CompanyName': 'Doit fournir au moins un nom de compagnie de voiture',
    'Must Provide At Least ONE Car Company Name': 'Doit fournir au moins un nom de compagnie de voiture',
    'Must Select One': 'Doit en sélectionner au moins un',
    'Language is required': 'La langue est requise',
    'TLC License # required': 'Le numéro de licence TLC est requis',
    'TLC # must match': 'Le numéro TLC doit correspondre'
  },
  other: {
    page1: 'Page 1 de 3',
    page2: 'Page 2 de 3',
    page3: 'Page 3 de 3',
    mainTitle: 'Activez vos avantages',
    eligleDrivers: "Tous les chauffeurs éligibles des services de voitures noires de NY, y compris Lyft, Uber et les bases de voitures noires plus traditionnelles de l'industrie, peuvent s'inscrire gratuitement pour recevoir un précieux ensemble d'avantages pour les chauffeurs, fournis par The Black Car Fund et l'Independent Drivers Guild!",
    compleInfo:
    'Remplissez les informations ci-dessous pour activer vos avantages de chauffeur. Ou appelez-nous au',
    hours: 'du lundi au vendredi de 8h00 à 20h00.',
    next: 'Suivant',
    submit: 'Soumettre',
    info: "Vous n'avez pas de numéro de licence TLC ? Entrez le numéro de votre permis de conduire si vous ne conduisez pas à New York.",
    link: "Vous ne savez pas ce qu'est TLC ?",
    here: 'ici',
    byCompleteInfo: "En remplissant les informations ci-dessus et en cliquant sur « Soumettre », vous confirmez que vous avez lu, compris et accepté d'être lié par nos Conditions d'Utilisation, qui peuvent être trouvées",
    youAlso: 'Vous acceptez également que nous puissions utiliser et divulguer vos informations tel que décrit dans notre Politique de Confidentialité, qui peut être trouvée',
    facts: 'Faits que vous devriez savoir',
    asYouMayKnow: "Comme vous le savez peut-être, le Black Car Fund fournit aux conducteurs de l'industrie des voitures noires des avantages en matière de compensation des travailleurs. Afin d'aider ses conducteurs couverts à rester en bonne santé et de réduire les accidents qui entraînent des blessures, le Black Car Fund parraine ce programme dans le but de réduire les réclamations d'indemnisation pour blessures et frais médicaux. Le programme vous est fourni en tant que conducteur couvert par le Black Car Fund et n'a pas pour but de vous accorder des droits d'emploi ou d'affecter autrement votre statut de travailleur indépendant vis-à-vis du Black Car Fund ou de la base de dispatch à laquelle vous êtes affilié. Bien que nous espérions que vous participerez au programme, celui-ci est entièrement volontaire et vous n'êtes pas obligé de participer. Si vous êtes un employé conducteur, ce programme n'est pas parrainé ou approuvé par votre employeur. Le programme n'est pas destiné à être couvert par la loi Employee Retirement Income Security Act (ERISA) ou la loi Patient Protection and Affordable Care Act ('PPACA'). Si vous vous inscrivez aux avantages de télémédecine disponibles dans le cadre du programme, vous pourriez devenir inéligible pour contribuer à un compte d'épargne santé. Consultez votre conseiller fiscal pour plus d'informations. Le Black Car Fund se réserve le droit de réviser ou de mettre fin à ce programme en totalité ou en partie à tout moment et pour toute raison.",
    questions: 'Des questions ?',
    callUs: 'Appelez-nous du lundi au vendredi de 8 h 00 à 20 h 00 (HNE) au',
    fasterAccess: 'Pour un accès plus rapide, téléchargez notre application mobile :',
    thankYou: 'Merci !',
    emailYouSoon: "Nous vous enverrons bientôt un e-mail sur la façon d'utiliser vos nouveaux avantages. Assurez-vous de vérifier votre dossier de spam.",
    clickHere: "Cliquez ici pour voir les avantages auxquels vous pourrez accéder"
  },
  systemMessages: {
    blocking: {
      title: 'Temporairement Indisponible',
    },
  },
}
