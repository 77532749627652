import { createContext } from 'react'

export enum FormVariantEnum {
  REACT_V1 = 'BCF-React-Lucy-V1',
  REACT_V2 = 'BCF-React-Lucy-V2',
}

type FormVariant = typeof FormVariantEnum
interface ILeadObjectKeys {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string>
    | FormVariant
    | undefined
}
export interface ILead extends ILeadObjectKeys {
  email: string
  firstName?: string
  lastName: string
  gender?: string
  phoneNumber?: string
  dateOfBirth?: string
  streetAddress?: string
  city?: string
  stateSelectHome?: string
  zipCode?: string
  tlcNumber?: string // formerly driversLicenseNumber
  dlConfirm?: string
  carCompanies?: Array<string>
  preferredLanguage?: string
  doYouHaveHealthInsurance?: string
  coverageThroughTheState?: string
  payMoreThan20?: string
  contracts?: Array<string>
  formDropoff: boolean
  formVariant?: string
  utm?: string
  browserDevice?: string
  browserLanguage?: string
  form: string
  status: string
  id?: string
}

export const leadInitialValue: Partial<ILead> = {
  email: '',
  firstName: '',
  lastName: '',
  gender: '',
  phoneNumber: '',
  dateOfBirth: '',
  streetAddress: '',
  city: '',
  stateSelectHome: '',
  zipCode: '',
  tlcNumber: '', // formerly driversLicenseNumber
  dlConfirm: '',
  preferredLanguage: '',
  carCompanies: [],
  doYouHaveHealthInsurance: undefined,
  // coverageThroughTheState: undefined,
  // payMoreThan20: undefined,
  formVariant: localStorage.getItem('formVariant') || FormVariantEnum.REACT_V2,
  formDropoff: true,
  utm: undefined,
  contracts: [],
  browserDevice: undefined,
  browserLanguage: undefined,
  form: process.env.REACT_APP_URL as string,
  status: undefined,
  id: '',
}

export const leadReducer = (state: ILead, payload: ILead) => {
  return {
    ...state,
    ...payload,
  }
}

export const LeadContext = createContext<{
  lead: Partial<ILead>
  dispatch: React.Dispatch<React.SetStateAction<Partial<ILead>>>
} | null>(null)
