import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { ILead, LeadContext } from '../../store/LeadContext'
import { validationSchema1 } from '../../common/utils/validationSchema'
import { useNavigate, useLocation } from 'react-router-dom'
import { settingDataToSessionStorage } from '../../utils/storage'
import { isMobile, isDesktop } from 'react-device-detect'
import { formAInputs } from '../../common/utils/formInput-values'
import { createLead } from '../../services/lead-api'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import { IFormProps } from 'src/common/utils/interfaces'

export const FormAV2 = ({ setStorageData, storageData, language }: IFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const browserLang = navigator.language
  const userAgent = navigator.userAgent
  const { search } = useLocation()

  const [formDataSent, setFormDataSent] = useState(false)
  const leadContext = useContext(LeadContext)

  const sendData = async (values: Partial<ILead>) => {
    if (!leadContext) {
      throw new Error('Lead context not found')
    }
    const searchParams = new URLSearchParams(search)
    values.browserLanguage = browserLang
    values.browserDevice = userAgent
    values.formDropoff = true
    values.utm = searchParams.get('utm_source') || ''
    values.contracts =
      searchParams.get('contract') !== null
        ? [`${searchParams.get('contract')}`]
        : ['00000101']

    if (values.lastName === '') {
      values.lastName = '  '
    }

    try {
      const lead: ILead = await createLead(values)
      values.id = lead.id
      leadContext?.dispatch(values)
      return lead.id
    } catch (error) {
      console.log('error', error)
    }
  }

  const formik = useFormik<Partial<ILead>>({
    initialValues: storageData,
    validationSchema: validationSchema1,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      ReactGA.event({
        category: '1st Page Next Button',
        action: '1st Page Next Button Clicked',
      })

      setStorageData((prev) => {
        const newState = {
          ...prev,
          ...values,
        }
        settingDataToSessionStorage(newState)
        return newState
      })

      if (isDesktop) {
        try {
          const leadId = await sendData(values)
          navigate(`/${language}/db-page-2/${leadId}?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
        } catch (ex) {
          console.log(ex)
        }
      }
    },
  })

  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = formik

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const value = e.target.value

    if (name === 'email') {
      const filteredValue = e.target.value.replace(/[^a-zA-Z0-9-+._@]/g, '')
      setFieldValue(name, filteredValue)
      setFieldTouched(name)
      return
    }

    if (name === 'firstName' || name === 'lastName') {
      const filteredValue = value.replace(/[^a-zA-Z\s-]/g, '')
      setFieldValue(name, filteredValue)
      setFieldTouched(name)
      return
    }
    if (name === 'phoneNumber') {
      const filteredValue = value.replace(/[^\d]/g, '')
      const filteredValueLength = filteredValue.length
      if (filteredValueLength < 4) {
        setFieldValue(name, filteredValue)
        setFieldTouched(name)
        validateField(name)
        return
      }

      if (filteredValueLength < 7) {
        setFieldValue(
          name,
          `(${filteredValue.slice(0, 3)}) ${filteredValue.slice(3)}`,
        )
        setFieldTouched(name)
        validateField(name)
        return
      }
      setFieldValue(
        name,
        `(${filteredValue.slice(0, 3)}) ${filteredValue.slice(
          3,
          6,
        )}-${filteredValue.slice(6, 10)}`,
      )
      setFieldTouched(name)
      validateField(name)
      return
    }
  }

  const handleMobileOnBlur = async () => {
    const { values } = formik
    if (
      isMobile &&
      values.email &&
      !errors.email &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.phoneNumber &&
      !formDataSent
    ) {
      try {
        setFormDataSent(true)
        const leadId = await sendData(values)
        navigate(`/${language}/db-page-2/${leadId}?utm_source=${(new URLSearchParams(search)).get('utm_source') || ''}`)
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  return (
    <form className="formA" onSubmit={handleSubmit}>
      {formAInputs.map((formAInput) => {
        const { name, type } = formAInput
        return (
          <div className="form-field" key={name}>
            <label htmlFor={name}>
              <div className="inputLabels">
                <p className="field">{t(`label.${name}`)}</p>
                {touched[name] && errors[name] && (
                  <span className="errorMessage">
                    {t(`error.${errors[name]}`)}
                  </span>
                )}
              </div>
              <input
                id={name}
                placeholder={t(`placeholder.${name}`) || ''}
                type={type}
                {...getFieldProps(name)}
                onChange={(e) => handleOnChange(e, name)}
                onBlur={name === 'phoneNumber' ? handleMobileOnBlur : undefined}
              />
            </label>
          </div>
        )
      })}

      <button type="submit" disabled={isSubmitting}>
        {t('other.next')}
      </button>
    </form>
  )
}
